import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'


export function getAlertsRequest(data = null) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Alert/getAlerts',
            method: "POST",
            data: data || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getAlertByIdRequest(alertId: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Alert/getAlertById',
            method: "POST",
            data: { alarmId: alertId },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}