
import { Vue } from "vue-class-component";
import {getNotSynchronizedInterventionContactsRequest, getNotSynchronizedInterventionsRequest} from "@/services/BackgroundTasks";
import {
	getInterventionTypesRequest,
	sendContactsToLisaRequest,
	sendInterventionsToLisaRequest
} from "@/services/InterventionService";
import {hideLoadingLayer, showLoadingLayer, validateWeekday} from "@/services/Globals";
import {hide} from "@popperjs/core";
import Swal from "sweetalert2";

declare let $: any

export default class NotSynced extends Vue{
	contactConf: any = []
	interventionConf: any = []
	interventionTypesConf: any = []
	timeRangeConf : any = {
		0: "Ganztätig",
		1: "Innerhalb der Anwesenheitszeiten",
		2: "Außerhalb der Anwesenheitszeiten",
	}
	
	formatTime(start: any, end: any){
		const validateTime = validateWeekday(start, end)
		const timeStart = validateTime.start.hours+':'+validateTime.start.minutes
		const timeEnd = validateTime.end.hours+':'+validateTime.end.minutes
		// item.weekday = validateTime.weekday
		return timeStart+' - '+timeEnd
	}
	
	getWeekday(start: any, end: any) {
		const validateTime = validateWeekday(start, end)
		const weekday = validateTime.weekday
		return weekday
	}
	
	sendContactsToLisa() {
		sendContactsToLisaRequest().then((res: any) => {
			// existiert nicht mehr
		})
	}
	
	sendInterventionsToLisa() {
		showLoadingLayer()
		
		sendInterventionsToLisaRequest().then((res: any) => {
			hideLoadingLayer()
			
			$('.ui-pnotify').remove()
			
			if(res.status == "OK") {
				Swal.fire({
					icon: 'success',
					title: 'Die Änderungen wurden erfolgreich an die Notrufleitstelle übertragen. ' +
						'Ab sofort gilt der neue Alarmplan. Sie können sich nun Ihren neuen Alarmplan im Menüpunkt ' +
						'Alarmpläne ansehen.',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				}).then(() => {
					location.reload()
				})
				
			}
		})
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypesConf = res.result
		})
		
		await getNotSynchronizedInterventionContactsRequest().then((res: any) => {
			console.log(res)
			if ( res.result ) {
				this.contactConf = res.result
			}
		})
		
		await getNotSynchronizedInterventionsRequest().then((res: any) => {
			console.log(res)
			if ( res.result ) {
				this.interventionConf = res.result
			}
		})
		
		hideLoadingLayer()
	}
}
