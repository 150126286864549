
import {Options, Vue} from "vue-class-component";


@Options({
    props: ['data']
})
export default class ObjectRow extends Vue {
    project_name: string =  this.dataProps?.p_firma || this.dataProps?.p_vorname+' '+this.dataProps?.p_name || ''
    heinz: any = this.dataProps
    
    get dataProps(): any {
        console.log(this.data)
        return this.data
    }
    
    
    showDetail(data: any): any {
        this.$emit('clicked', data)
    }
    
    goToObject():any {
        this.$router.push({name: "ObjectOverview", params: {id: this.dataProps.pid}})
    }
    
}
