
import { Vue } from "vue-class-component";
import moment from "moment";
import {getInterventionContactsLogsRequest} from "@/services/InterventionService";

export default class InterventionContacts extends Vue {
	headline = "Interventionskontakte Logs"
	logs: any = []
	moment = moment
	
	getLogs() {
		getInterventionContactsLogsRequest().then((res: any) => {
			if ( res.status == "OK" && res.result.length > 0 ) {
				this.logs = res.result
			}
		})
	}
	
	beforeMount() {
		this.getLogs()
	}
}
