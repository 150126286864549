
import { Vue } from "vue-class-component"
import SignaturePad from "signature_pad";

export default class Signature extends Vue {
	signaturePad: any = null
	canvas: any = null
	
	clearPad() {
		this.signaturePad.clear()
		
		this.$emit('update', { data: [], image: "" })
	}
	
	resizeCanvas() {
		// const ratio =  Math.max(window.devicePixelRatio || 1, 1);
		this.canvas.width = 0
		this.canvas.height = 0
		
		let container: any = document.getElementById('signature_container')
		
		const containerRect = container.getBoundingClientRect()
		const containerWidth = containerRect.width
		const containerHeight = containerRect.height
		
		this.canvas.width = containerWidth
		this.canvas.height = containerHeight
		
		this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
	}
	
	mounted() {
		this.canvas = document.getElementById('signature-pad')
		
		this.signaturePad = new SignaturePad(this.canvas, {
			backgroundColor: 'rgba(255, 255, 255, 0)',
			penColor: 'rgb(1,0,98)',
			minWidth: navigator.userAgent.includes('Mobile') ? 1 : 1,
			maxWidth: navigator.userAgent.includes('Mobile') ? 1 : 5
		});
		
		this.resizeCanvas()
		
		this.signaturePad.addEventListener('endStroke', () => {
			const data = this.signaturePad.toData()
			const image = this.signaturePad.toDataURL()
			this.$emit('update', { data, image })
		})
		
		window.addEventListener("resize", this.resizeCanvas);
	}
	
}
