
import {getProjectsOverviewRequest, printSwitchingTimesOverviewRequest} from "@/services/ObjectService";
import {Options, Vue} from "vue-class-component";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {printContactsOverviewRequest, printInterventionsOverviewRequest } from "@/services/InterventionService";

@Options({
	watch: {
		type() {
			this.getObjects()
		}
	}
})
export default class Main extends Vue {
	objects: any = []
	
	get headline() {
		let headline = ""
		
		switch (this.type) {
			case 'interventions':
				headline = "Interventionen"
				break
			case 'switching-times':
				headline = "Schaltzeiten"
				break
			case 'location-maps':
				headline = "Lagepläne"
				break
			case 'contacts':
				headline = "Kontakte"
				break
			case 'alerts':
				headline = "Alarme"
				break
			case 'rights':
				headline = "Rechte"
				break
		}
		
		return headline
	}
	
	get type() {
		return this.$route.params.type || null
	}
	
	printOverview(id: any = null) {
		let extendUri = ""
		if ( id ) extendUri = "&objectId="+id
		
		switch (this.type) {
			case 'interventions':
				window.open(this.$getFileUrl + "?t=intervention_overview&_token=" + this.$store.getters.user.accessToken+extendUri )
				/*
				printInterventionsOverviewRequest(data).then((res: any) => {
					window.open("https://"+res.result.filePath)
				})
				 */
				break
			case 'switching-times':
				window.open(this.$getFileUrl + "?t=switching_times_overview&_token=" + this.$store.getters.user.accessToken+extendUri )
				/*
				printSwitchingTimesOverviewRequest(data).then((res: any) => {
					window.open("https://"+res.result.filePath)
				})
				 */
				break
			case 'location-maps':
				window.open(this.$getFileUrl + "?t=location_maps_overview&_token=" + this.$store.getters.user.accessToken+extendUri )
				// headline = "Lagepläne"
				break
			case 'contacts':
				window.open(this.$getFileUrl + "?t=intervention_contacts_overview&_token=" + this.$store.getters.user.accessToken+extendUri )
				/*
				printContactsOverviewRequest(data).then((res: any) => {
					window.open("https://"+res.result.filePath)
				})
				 */
				break
			case 'alerts':
				// headline = "Alarme"
				break
			case 'rights':
				// headline = "Rechte"
				break
		}
		
	}
	
	getObjects() {
		showLoadingLayer()
		const data = {  //ZR-54 change
			filter: {
				status: [1,20]
			}
		}
		getProjectsOverviewRequest(data).then((res: any) => {
			if ( res.result?.projekte?.length > 0 ) {
				this.objects = res.result.projekte
			}
			
			hideLoadingLayer()
		})
	}
	
	beforeMount() {
		this.getObjects()
	}
}
