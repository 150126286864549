
import { Vue } from "vue-class-component";
import {hideLoadingLayer, showLoadingLayer, stringToDate} from "@/services/Globals";
import { getTicketsRequest } from "@/services/TicketService";

export default class Tickets extends Vue {
	headline = "Ticketübersicht"
	tickets: any[] = []
	
	newTicket() {
		this.$router.push({ name: "NewTicket" })
	}
	
	showDetail(action: string, targetId: number) {
		switch (action) {
			case 'alert_detail':
				this.$router.push({ name: "AlertOverview", params: { id: targetId } })
				break
			case 'ticket_alert':
				this.$router.push({ name: "TicketDetail", params: { id: targetId } })
				break
			case 'ticket_standard':
				this.$router.push({ name: "TicketDetail", params: { id: targetId } })
				break
		}
		
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getTicketsRequest().then((res: any) => {
			if ( res.result && res.result.length >= 0 ) {
				res.result.map((item: any) => {
					item.date = stringToDate(item.createdAt.split(' ')[0], 'de')
					item.time = item.createdAt.split(' ')[1]
					
					this.tickets.push(item)
				})
			}
			
			hideLoadingLayer()
		})
	}
}
