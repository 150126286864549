
import {Options, Vue} from 'vue-class-component'
import {
	getEmployeeById,
	getEmployeeGroupsByEmployeeRequest,
	getEmployees,
	reInviteEmployeeRequest,
	saveEmployeeRequest,
	getInterventionsAndContactsByUserId
} from "@/services/EmplyeeService";
import {_countries} from "@/config/conf";
import {getProjectsOverviewRequest} from "@/services/ObjectService";
import {getInterventionTypesRequest} from "@/services/InterventionService";
import Swal from 'sweetalert2'
import {_userStatus, hideLoadingLayer, showLoadingLayer, validatePhoneNumberRequest} from "@/services/Globals";
import EditInterventionNumber from "@/components/EditInterventionNumber.vue";
import {checkForNotSynced} from "@/services/BackgroundTasks";

@Options({
	components: { EditInterventionNumber }
})
export default class EmployeeDetails extends Vue {
	countries = _countries
	user: any = {
		id: { name: 'ID', value: '' },
		supervisor: { name: 'Vorgesetzter', value: '' },
		firstName: { name: 'Vorname', value: '' },
		lastName: { name: 'Nachname', value: '' },
		jobTitle: { name: 'Position', value: '' },
		tel: { name: 'Festnetz', value: '' },
		mobil: { name: 'Handy', value: '' },
		interventionNumber: { name: 'Interventionsnummer', value: '' },
		email: { name: 'Email', value: '' },
		street: { name: 'Straße', value: '' },
		houseNumber: { name: 'Hnr', value: '' },
		zipCode: { name: 'Plz', value: '' },
		city: { name: 'Ort', value: '' },
		country: { name: 'Land', value: '' },
		rights: {
			projects: [],
			user: { read: false, write: false },
			invoice: { read: false, write: false },
			contracts: { read: false, write: false },
			company: { read: false, write: false },
			arming: { read: false, write: false },
			interventions: { read: false, write: false}
		},
		groups: [],
		role: 0,
		status: { name: 'Status', value: 0 },
		invitation: { name: 'Einladung', value: 0 },
		verified: { name: 'Verifizierung', value: 0 }
	}
	userStatus = _userStatus
	projects:any = {}
	users: any = []
	groups: any = {}
	getInterventionsAndContactsByUserId: any = {}
	interventionTypes: any = {}
	
	get userId():any {
		return this.$route.params?.id ? this.$route.params.id : null
	}
	
	get headline() {
		return this.$route.params.id ? `Benutzer - ${this.user.firstName.value} ${this.user.lastName.value}` : 'Neuer Benutzer'
	}
	
	get userRights() {
		return this.$store.getters.rights
	}

	isUserAnAnsprechpartner(project:any) {
		if (this.user.email.value === project.p_email) {
			return true;
		}
		return false;
	}

	navigateToSustemIntervention(pid: any, systemId: any, intId: any) {
		this.$router.push({path: `/object/${pid}/system/${systemId}/intervention-intervention-types/${intId}`})
	}

	navigateToAnsprechpartner(pid:any) {
		this.$router.push({path: `/object/${pid}/contact-person`})
	}

	getInterventionName(contactByProject: any, systemInterventions: any, pid: any) {
		let name = '';
		systemInterventions.forEach((inte: any) => {
			if (
				(inte.contactList?.includes(this.userId) && (inte.contactOrder.includes('contactPerson')))
				||
				(inte.contactOrder.includes('object') && this.userId === contactByProject.p_intervention_object_avz_user)
			) {
				name = `${pid} ${contactByProject.p_firma ? contactByProject.p_firma :  contactByProject.p_vorname+" "+ contactByProject.p_name}; ${inte.anlage_nummer}; ${inte.systemName}; ${this.interventionTypes[inte.interventionType].name}`;
			}
		});
		return name;
	}
	
	isUserAContactInObject(projectDetails: any ){
		if (this.userId == projectDetails.p_intervention_object_avz_user) {
			return `${projectDetails.pid} ${projectDetails.p_firma ? projectDetails.p_firma :  projectDetails.p_vorname+" "+ projectDetails.p_name}; KontaktImObjekt`;
		}
	}

	reInvite() {
		showLoadingLayer()
		
		reInviteEmployeeRequest(this.userId).then((res: any) => {
			hideLoadingLayer()
			
			if ( res.status == "OK" ) {
				return Swal.fire({
					title: "Erfolg",
					text: "Ihre Einladung wurde erfolgreich verschickt",
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn btn-primary"
					}
				})
			}
			
			return Swal.fire({
				icon: "error",
				title: "Fehler",
				html: "Der Nutzer konnte nicht eingeladen werden. <br> Bitte prüfen Sie die Emailadresse",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-primary"
				}
			})
		})
	}
	
	onPhoneSuccess(data: any) {
		console.log(data)
	}
	
	validatePhoneNumber(field: string) {
		const number: string = this.user[field].value
		
		validatePhoneNumberRequest(number, this.user.country.value).then((res: any) => {
			if ( res.status === "ERROR" ) {
				//
			} else {
				this.user[field].value = res.result
			}
		})
	}
	
	onProjectRightChange(evt: any, pid: string|number) {
		if ( evt.target.checked ) {
			this.user.rights.projects.push(pid)
		} else {
			const index = this.user.rights.projects.indexOf(pid)
			this.user.rights.projects.splice(index, 1)
		}
	}
	
	onGroupChange(evt: any, id: string|number) {
		if ( evt.target.checked ) {
			this.user.groups.push(id)
		} else {
			const index = this.user.groups.indexOf(id)
			this.user.groups.splice(index, 1)
		}
	}
	
	saveEmployee() {
		let user = {
			supervisor: this.user.supervisor.value,
			firstName: this.user.firstName.value,
			lastName: this.user.lastName.value,
			jobTitle: this.user.jobTitle.value,
			tel: this.user.tel.value,
			email: this.user.email.value,
			street: this.user.street.value,
			houseNumber: this.user.houseNumber.value,
			zipCode: this.user.zipCode.value,
			city: this.user.city.value,
			country: this.user.country.value,
			rights: this.user.rights,
			status: this.user.status.value,
			groups: this.user.groups
		}
		
		// user.rights.company.read = user.rights.company.write
		// user.rights.arming.read = user.rights.arming.write
		
		if ( this.userId && this.userId != null ) Object.assign(user, { id: this.userId })

		if (this.user.status.value == '3') { // if user is blocked, remove his access to projects and read write rights
			user.rights = {
				projects: [],
				user: { read: false, write: false },
				invoice: { read: false, write: false },
				contracts: { read: false, write: false },
				company: { read: false, write: false },
				arming: { read: false, write: false },
				interventions: { read: false, write: false}
			}
		}
		
		saveEmployeeRequest(user).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Datensatz erfolgreich gespeichert';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			if ( res.status == 'OK' ) {
				Swal.fire({
					icon: icon,
					title: message,
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
				// checkForNotSynced()
				if ( this.userId === null ) return this.$router.push({name: 'EmployeeDetails', params: {id: res.result}})
			} else {
				Swal.fire({
							title: "Fehler",
							text: res?.result
				})
			}
		})
	}


	getInterventionTypes() {
		getInterventionTypesRequest().then((res: any)=>{
			this.interventionTypes = res.result;
		}).catch((error:any)=>{
			console.log(error);
		})
	}
	
	getEmployeeAsContact() {
		getInterventionsAndContactsByUserId().then((res: any)=>{
			this.getInterventionsAndContactsByUserId = res.result;
		}).catch((error:any)=>{
			console.log(error);
		})
	}

	beforeMount() {
		// get projects, only new:1, active:20 ones, ZR-54
		const data = {
			filter: {
				status: [1, 20]
			}
		};
		getProjectsOverviewRequest(data).then((res: any) => {
			this.projects = {}
			if ( !res.result ) return false
			
			res.result.projekte.map((project: any) => {
				this.projects[project.pid] = project
			})
		})
		
		getEmployeeGroupsByEmployeeRequest().then((res: any) => {
			if ( res.status === "OK" && res.result.length > 0 ) {
				res.result.map((item: any) => {
					this.groups[item.id] = item
				})
			}
		})
		
		getEmployees().then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					this.users.push(emp)
				})
			}
		})
		
		if ( this.userId ) {
			getEmployeeById(this.userId).then((res: any) => {
				if ( !res.result ) return false
				
				const user = res.result
				
				this.user.id.value = user.id
				this.user.supervisor.value = user.supervisor
				this.user.firstName.value = user.firstName
				this.user.lastName.value = user.lastName
				this.user.jobTitle.value = user.jobTitle
				this.user.tel.value = user.tel
				this.user.email.value = user.email
				this.user.street.value = user.street
				this.user.houseNumber.value = user.houseNumber
				this.user.zipCode.value = user.zipCode
				this.user.city.value = user.city
				this.user.country.value = user.country
				this.user.role = user.role
				this.user.rights.projects = user.rights.projects || []
				this.user.groups = user.groups || []
				this.user.rights.user = user.rights.user || { read: false, write: false }
				this.user.rights.invoice = user.rights.invoice || { read: false, write: false }
				this.user.rights.contracts = user.rights.contracts || { read: false, write: false }
				this.user.rights.company = user.rights.company || { read: false, write: false }
				this.user.rights.arming = user.rights.arming || { read: false, write: false }
				this.user.rights.interventions = user.rights.interventions || { read: false, write: false }
				this.user.status.value = user.status
				this.user.invitation.value = user.invitation
				this.user.verified.value = user.verified
			})
			this.getInterventionTypes();
			this.getEmployeeAsContact();
			
		}
		
	}
}
