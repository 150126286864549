
import {Options, Vue} from "vue-class-component";

@Options({
	props: {
		ticket: { default: {} }
	}
})
export default class StandardRequest extends Vue {
	ticket: any = {}
}
