
import {Options, Vue} from "vue-class-component";
import {getEmployees} from "@/services/EmplyeeService";
import {getFilterRequest, _userStatus, showLoadingLayer, hideLoadingLayer} from "@/services/Globals";
import FilterBar from "@/components/FilterBar.vue";

@Options({ components: { FilterBar } })
export default class Employees extends Vue {
	headline = 'Benutzerverwaltung'
	users: any[] = []
	userStatus: any = _userStatus
	search = ''
	filter: any = {}
	filterArr: any = {}
	
	get userRights() {
		return this.$store.getters.rights
	}
	
	get objectId() {
		return this.$route.params.id || null
	}
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		this.getEmps()
	}
	
	editEmployee(user: any) {
		this.$router.push({name: 'EmployeeDetails', params: {id: user.id}})
	}
	
	newEmployee() {
		this.$router.push({name: 'NewEmployee'})
	}
	
	getEmps(){
		const data: any = {
			search: this.search,
			filter: this.filterArr
		}
		
		if ( this.objectId ) data.objectId = this.objectId
		
		getEmployees(data).then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					emp.status = parseInt(emp.status)
					this.users.push(emp)
				})
			}
			
			hideLoadingLayer()
		})
	}

	getPreloadedFilters() {
		this.filter.status.items.active.checked = true;
		this.filter.status.items.invited.checked = true;
		this.filterArr.status = [0, 2];
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getFilterRequest('employees').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			this.filter = res.result.filter
			this.getPreloadedFilters();
			this.getEmps()
		})
	}
	
}

