
import { Vue, Options } from "vue-class-component"
import { _countries } from '@/config/conf'
import { getUserData, saveUserDataRequest } from "@/services/UserService"
import { validatePhoneNumberRequest } from "@/services/Globals"
import Swal from "sweetalert2";
import {resetPasswordRequest} from "@/services/AuthService";

@Options({})
export default class UserData extends Vue {
	countries = _countries
	frmUser: any = {
		kid: { name: 'Kundennummer', value: '', error: null },
		id: { name: 'Benutzer - ID', value: '', error: null },
		salutation: { name: 'Anrede', value: '', error: null },
		title: { name: 'Titel', value: '', error: null },
		company: { name: 'Firma', value: '', error: null },
		firstName: { name: 'Vorname', value: '', error: null },
		lastName: { name: 'Name', value: '', error: null },
		street: { name: 'Straße', value: '', error: null },
		houseNumber: { name: 'Hnr', value: '', error: null },
		zipCode: { name: 'Plz', value: '', error: null },
		city: { name: 'Ort', value: '', error: null },
		additionalAddress: { name: 'Adresszusatz', value: '', error: null },
		country: { name: 'Land', value: '', error: null },
		tel: { name: 'Festnetz', value: '', error: null },
		mobil: { name: 'Handy', value: '', error: null },
		email: { name: 'Email', value: '', error: null },
		birthday: { name: 'Geburtstag', value: '', error: null },
	}
	resetSuccess = false
	
	resetPassword() {
		resetPasswordRequest(this.frmUser.email.value).then((res: any) => {
			if ( res.status == 'OK' ) {
				this.resetSuccess = true
			}
		})
	}
	
	validatePhoneNumber(field: string) {
		const number: string = this.frmUser[field].value
		
		validatePhoneNumberRequest(number, this.frmUser.country.value).then((res: any) => {
			if ( res.status === "ERROR" ) {
				//
			} else {
				this.frmUser[field].value = res.result
			}
		})
	}
	
	getUserData() {
		getUserData().then((res: any) => {
			if ( !res.result ) return false
			const userData = res.result
			
			this.frmUser.kid.value = userData.kid
			this.frmUser.id.value = userData.id
			this.frmUser.salutation.value = userData.salutation
			this.frmUser.title.value = userData.titel
			this.frmUser.company.value = userData.company
			this.frmUser.firstName.value = userData.firstName
			this.frmUser.lastName.value = userData.lastName
			this.frmUser.street.value = userData.street
			this.frmUser.houseNumber.value = userData.houseNumber
			this.frmUser.zipCode.value = userData.zipCode
			this.frmUser.city.value = userData.city
			this.frmUser.country.value = userData.country
			this.frmUser.additionalAddress.value = userData.adresszusatz
			this.frmUser.tel.value = userData.tel
			this.frmUser.mobil.value = userData.mobil
			this.frmUser.email.value = userData.email
			this.frmUser.birthday.value = userData.geb_dat
		})
	}
	
	saveUserData() {
		let data: any = {}
		const frmArr = Object.keys(this.frmUser)
		
		frmArr.map((key: any) => {
			data[key] = this.frmUser[key].value
		})
		
		saveUserDataRequest(data).then((res: any) => {
			if ( res.status === "ERROR" ) {
				const errors = res.result.errorFields
				const errorKeys = Object.keys(errors)
				
				Swal.fire({
					icon: "error",
					title: "Fehler",
					text: res.result.errorMsg,
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-danger'
					}
				})
				
				errorKeys.map((item: any) => {
					this.frmUser[item].error = errors[item]
				})
			} else {
				Swal.fire({
					icon: "success",
					title: "Erfolgreich",
					text: "Daten wurden erfolgreich übernommen",
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
			}
		})
	}
	
	beforeMount() {
		this.getUserData()
	}
	
}
