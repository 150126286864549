
import $ from 'jquery';
import { Vue, Options } from "vue-class-component";
import {changeInterventionNumberRequest, changePhoneNumberRequest, getUserData} from "@/services/UserService";
import Swal from "sweetalert2";
import {getPhoneCodesRequest, validatePhoneNumberRequest} from "@/services/Globals";
import {getEmployeeById} from "@/services/EmplyeeService";
import {checkForNotSynced} from "@/services/BackgroundTasks";

@Options({
	props: {
		userId: {
			default: null
		}
	}
})
export default class EditInterventionNumber extends Vue{
	frm: any = {
		tel: { name: 'Festnetz', value: '', default: '', error: null, disabled: true },
		mobil: { name: 'Handy', value: '', default: '', error: null, disabled: true },
		interventionNumber: { name: 'Interventionsnummer', value: 'mobil', error: null, disabled: true },
		areaCodeTel: { name: '', value: 'DE', default: '', error: null, disabled: true },
		areaCodeMobil: { name: '', value: 'DE', default: '', error: null, disabled: true }
	}
	phoneCodes: any;
	selectable: any = {
		tel: false,
		mobil: false
	}
	userId: any
	btnSaveTelDisabled = false
	btnSaveMobilDisabled = false
	phoneCode: any
	phoneNumberConfirmation: any

	
	
	changeIntNumber() {
		// validate intervention number
		changeInterventionNumberRequest(this.frm.interventionNumber.value, this.userId).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Ihr Interventionskontakt wurde erfolgreich geändert\nÄnderungen müssen noch übertragen werden';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			
					if ( res.status === "ERROR" ) {
						this.closeEdit("interventionNumber")
						return Swal.fire({
							title: "Fehler",
							text: "Es ist ein Fehler aufgetreten"
						})
					}
					
					Swal.fire({
						icon: icon,
						text: message,
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn btn-success'
						}
					})
					this.closeEdit("interventionNumber")
		})
		checkForNotSynced()
	}
	
	searchForAreaCodeInNumber(number: string){
		return new Promise(resolve => {
			const areaCodes = Object.keys(this.phoneCodes)
			areaCodes.map((code: any) => {
				const codeLength = this.phoneCodes[code].length
				if ( number.substring(0,codeLength) == this.phoneCodes[code] ) {
					const slicedNumber = number.slice(codeLength)
					
					resolve({
						areaCode: code,
						number: slicedNumber
					})
				}
			})
			resolve(false)
		})
	}
	
	async editNumber(key: any) {
		let res: any = false
		switch (key) {
			case "tel":
				this.frm.tel.disabled = false
				this.frm.areaCodeTel.disabled = false
				
				this.closeEdit('mobil')
				this.closeEdit('interventionNumber')
				
				res = await this.searchForAreaCodeInNumber(this.frm.tel.value)
				
				if ( res ) {
					this.frm.tel.value = res.number
					this.frm.areaCodeTel.value = res.areaCode
					this.phoneCode = this.frm.areaCodeTel.value
				}
				break
			
			case "mobil":
				this.frm.mobil.disabled = false
				this.frm.areaCodeMobil.disabled = false
				
				this.closeEdit('tel')
				this.closeEdit('interventionNumber')
				
				res = await this.searchForAreaCodeInNumber(this.frm.mobil.value)
				
				if ( res ) {
					this.frm.mobil.value = res.number
					this.frm.areaCodeMobil.value = res.areaCode
				}
				break
			
			case "interventionNumber":
				this.frm.interventionNumber.disabled = false
				
				this.closeEdit('tel')
				this.closeEdit('mobil')
				
				break
		}
	}
	
	closeEdit(key: any) {
		switch (key) {
			case "tel":
				this.frm.tel.disabled = true
				this.frm.areaCodeTel.disabled = true
				this.frm.tel.value = this.frm.tel.default
				break
			
			case "mobil":
				this.frm.mobil.disabled = true
				this.frm.areaCodeMobil.disabled = true
				this.frm.mobil.value = this.frm.mobil.default
				break
			
			case "interventionNumber":
				this.frm.interventionNumber.disabled = true
				break
		}
	}
	
	validatePhoneNumber(field: string) {
		const number: string = this.frm[field].value
		const areaCode = field === "tel" ? this.frm.areaCodeTel.value : this.frm.areaCodeMobil.value
		
		validatePhoneNumberRequest(number, areaCode).then((res: any) => {
			if ( res.status === "ERROR" ) {
				this.frm[field].error = res.result
			} else {
				this.frm[field].error = null
				const plainNumber = res.result.split(this.phoneCodes[areaCode])[1]
				this.frm[field].value = plainNumber
			}
		})
	}

	verifyConfirmationNumber(type: any) {
		const confirmationNumber = this.phoneCodes[this.phoneCode]+this.phoneNumberConfirmation
		const number = type === "tel" ? this.frm.tel.value : this.frm.mobil.value
		const areaCode = type === "tel" ? this.frm.areaCodeTel.value : this.frm.areaCodeMobil.value
		const phone = this.phoneCodes[areaCode]+number
		if ( phone === confirmationNumber) {
			this.changeNumberForType(number, areaCode, type);
			$("#phoneVerificationModal .close").click();
		} else {
			Swal.fire({
				title: 'Die eingegebene Nummer stimmt nicht überein.',
			});
		}
	}

	changeNumberForType(number: any, areaCode: any, type: any){
		// disable button if edit tel field
		this.btnSaveTelDisabled = type === "tel"
		// disable button if edit mobil field
		this.btnSaveMobilDisabled = type === "mobil"
		
		const reqData: any = {
			number, areaCode, type
		}
		
		if ( this.userId !== null ) reqData.userId = this.userId
		
		changePhoneNumberRequest(reqData).then((res: any) => {
			if (res.status === "ERROR") {
				if ( res.result.errorFields ) {
					const errors = res.result.errorFields
					const errorKeys = Object.keys(errors)
					
					errorKeys.map((item: any) => {
						this.frm[item].error = errors[item]
					})
				}
				
				Swal.fire({
					title: "Fehler",
					text: "Bitte bearbeiten Sie alle rot markierten Felder",
					icon: "error",
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			} else {
				this.$emit('success', { verified: res.result.verified, type: type, hash: res.result.hash })
				this.frm[type].default = this.phoneCodes[areaCode]+number
				this.closeEdit(type)
				checkForNotSynced()
			}
			
			this.btnSaveTelDisabled = false
			this.btnSaveMobilDisabled = false
		})
	}
	
	changeNumber(type: any) {
		const number = type === "tel" ? this.frm.tel.value : this.frm.mobil.value
		const areaCode = type === "tel" ? this.frm.areaCodeTel.value : this.frm.areaCodeMobil.value
		const numberDefault = type === "tel" ? this.frm.tel.default : this.frm.mobil.default
		// early return if number not changed
		if ( numberDefault === this.phoneCodes[areaCode]+number ) {
			Swal.fire({
				title: "Information",
				text: "Sie haben nichts geändert",
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn bg-info'
				}
			})
			return this.closeEdit(type)
		}

		if (type === "tel") {
			$("#openVerificationModal").click();
			return;
		}
		
		this.changeNumberForType(number, areaCode, type);
	}
	
	beforeMount() {
		getPhoneCodesRequest().then((res: any) => {
			this.phoneCodes = res.result
		})
		
		if ( this.userId ) {
			getEmployeeById(this.userId).then((res: any) => {
				if ( !res.result ) return false
				
				this.frm.mobil.value = res.result.mobil
				this.frm.mobil.default = res.result.mobil
				this.frm.tel.value = res.result.tel
				this.frm.tel.default = res.result.tel
				this.frm.interventionNumber.value = res.result.interventionNumber
				
				if ( res.result.tel !== "" ) this.selectable.tel = true
				if ( res.result.mobil !== "" ) this.selectable.mobil = true
			})
		} else {
			getUserData().then((res: any) => {
				if ( !res.result ) return false
				
				this.frm.mobil.value = res.result.mobil
				this.frm.mobil.default = res.result.mobil
				this.frm.tel.value = res.result.tel
				this.frm.tel.default = res.result.tel
				this.frm.interventionNumber.value = res.result.interventionNumber
				
				if ( res.result.tel !== "" ) this.selectable.tel = true
				if ( res.result.mobil !== "" ) this.selectable.mobil = true
			})
		}
	}
}
