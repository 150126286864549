
import { getTicketByIdRequest } from "@/services/TicketService";
import {Options, Vue} from "vue-class-component";
import Standard from "@/views/Ticket/TicketForms/Standard.vue";
import Alert from "@/views/Ticket/TicketForms/Alert.vue";
import {stringToDate} from "@/services/Globals";
import moment, {Moment} from "moment";

interface Ticket {
	id: number
	name: string
	extendName?: string
	action: null | string
}

@Options({
	components: {
		Standard, Alert
	}
})
export default class TicketDetail extends Vue {
	ticket: Ticket | any = {}
	frm: any = {}
	
	get today() {
		const today: Moment = moment()
		const todayDate = today.format('YYYY-MM-DD')
		return todayDate
	}
	
	get ticketId() {
		return this.$route.params.id || null
	}
	
	reloadTicketDetails() {
		getTicketByIdRequest({ id: this.ticketId }).then((res: any) => {
			if ( res.result ) {
				const ticket = res.result
				ticket.date = stringToDate(ticket.createdAt.split(' ')[0], 'de')
				ticket.time = moment(ticket.createdAt).format("HH:mm")
				
				if ( this.today == ticket.createdAt.split(' ')[0] ) ticket.date = "Heute"
				
				const logs: any = []
				ticket.logs?.map((log: any) => {
					log.date = stringToDate(log.createdAt.split(' ')[0], 'de')
					log.time = moment(log.createdAt).format("HH:mm")
					logs.push(log)
				})
				ticket.logs = logs
				this.ticket = res.result
				console.log(this.ticket)
			}
		})
	}
	
	beforeMount() {
		this.reloadTicketDetails()
	}
}
