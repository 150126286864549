
import { Vue, Options } from "vue-class-component";
import Signature from "@/components/SignaturePad/Signature.vue";

@Options({
	components: { Signature }
})
export default class Example extends Vue {
	signatureData: any = []
	image = ""
	paddingBottom = true
	
	updateSignature(args: any) {
		this.signatureData = args.data
		this.image = args.image
	}
	
	saveSignatureAsImage() {
		const image: any = document.createElement('img')
		image.src = this.image
		
		const w: any = window.open()
		w.document.write(image.outerHTML)
		
	}
	
	saveSignatureAsData() {
		const p: any = document.createElement('p')
		p.innerText = JSON.stringify(this.signatureData)
		
		const w: any = window.open()
		w.document.write(p.outerHTML)
	}
	
	resetSignature() {
		const pad: any = this.$refs.sign
		pad.clearPad()
	}
	
	resizeWindow() {
		const bodyRect = window.document.body.getBoundingClientRect()
		const bodyHeight = bodyRect.height
		const bodyWith = bodyRect.width
		
		if ( bodyHeight >= bodyWith ) { // horizontal
			this.paddingBottom = true
		} else { // vertical
			this.paddingBottom = false
		}
		
		console.log(bodyRect)
	}
	
	created() {
		window.addEventListener("resize", this.resizeWindow);
		
		if ( navigator.userAgent.includes('Mobile') ) {
			document.body.classList.add('sidebar-xs')
		} else {
			//
		}
		
		this.resizeWindow()
	}
}
