import axios from "axios";
import {conf} from "@/config/conf";
import store from "@/store";

export function saveCompanyRequest(data: any): any {
    return new Promise(( resolve: any, reject: any ) => {
        const request = axios({
            url: conf.apiUrl + '/Company/saveCompany',
            method: "POST",
            data:  data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}