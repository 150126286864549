
import {getInterventionTypesRequest, getSystemInterventionContactsRequest, saveSystemInterventionContactsRequest} from "@/services/InterventionService";
import {Options, Vue} from "vue-class-component";
import draggable from "vuedraggable";
import {getEmployees} from "@/services/EmplyeeService";
import Swal from "sweetalert2";
import {addInterruptedStep, clearInterruptedStep, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";

@Options({
	components: {draggable},
	props: {
		interventionType: {
			type: Number
		}
	}
})
export default class InterventionContacts extends Vue {
	interventionTypes: any = {}
	availableContacts: any[] = []
	contactOrder: any[] = []
	interventionType: any
	
	get headline() {
		// const interventionName = this.interventionName
		const headline = this.$route.meta.headline
		return `Kontakte im Fall ${ headline }`
	}
	
	get objectId() {
		return this.$route.params.id
	}
	
	get interventionName() {
		return this.interventionTypes[this.interventionType]?.name
	}
	
	get systemId(): any {
		return this.$route.params.system
	}

	get user() {
		return this.$store.getters.user
	}
	
	shouldDisableInterventionChanges() {
		if (!this.user.rights.interventions) {
			return true;
		}
		if (this.user.rights.interventions) {
			return this.user.rights.interventions.write
		}
	}

	checkElement(element: any){
		const index = this.availableContacts.indexOf(element)
		const elem = this.availableContacts.splice(index,1)
		this.contactOrder.push(elem[0])
	}
	
	removeElement(element: any){
		const index = this.contactOrder.indexOf(element)
		const elem = this.contactOrder.splice(index,1)
		this.availableContacts.push(elem[0])
	}
	
	goToEmployees() {
		// add to interrupted steps
		const route = this.$route.fullPath
		addInterruptedStep(route, 1).then((res: any) => {
			console.log(res)
		})
		// route to new employee
		this.$router.push({ name: 'Employees' })
	}
	
	takeOver() {
		this.$router.push({ name: 'ContactTakeOver', params: { type: this.interventionType } })
	}
	
	saveContacts() {
		let contacts: any[] = []
		this.contactOrder.map((contact: any) => {
			contacts.push(contact.id)
		})
		const data = {
			systemId: this.systemId,
			interventionType: this.interventionType,
			contacts: contacts,
			objectId: this.objectId
		}
		saveSystemInterventionContactsRequest(data).then((res: any) => {
			const message = res?.result?.message ? res.result.message : 'Kontakte erfolgreich übernommen';
			const status = res?.result?.status == 404 ? 'error' : 'success';
			if ( res.status === 'OK' ) {
				Swal.fire({
					title: message,
					icon: status,
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
				
				this.$emit('saved')
			}
		})
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		const systemId = this.$route.params.system
		const interventionType = this.interventionType
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
		const requestData = {
			objectId: this.objectId,
			filter: { 
				verification: [1],
				status: [2]
			}
		}
		
		await getEmployees(requestData).then(async (res1: any) => {
			if ( res1.status === 'OK', res1.result.length > 0 ) {
				// this.availableContacts = res1.result
				
				res1.result.map((item: any) => {
					item.phone = item.interventionNumber == "mobil" ? item.mobil : item.tel
					this.availableContacts.push(item)
				})
				
				
				
				// TODO : sometimes no available contact but is chosen
				await getSystemInterventionContactsRequest(systemId, interventionType).then((res: any) => {
					if ( res.status === 'OK', res.result.length > 0 ) {
						res.result.map((item: any) => {
							const index: number = this.availableContacts.findIndex(elem => elem.id == item.id)
							const value = this.availableContacts[index]
							if (index !== -1 ) {
								this.availableContacts.splice(index, 1)
							}
							if ( value ) {
								this.contactOrder.push(value)
							}
						})
					}
					
					hideLoadingLayer()
				})
			}
		})
		
		
	}
	
	mounted() {
		const route = this.$route.fullPath
		clearInterruptedStep(route).then((res: any) => {
			console.log(res)
		})
	}
}
