
import {Options, Vue} from 'vue-class-component'
import { getAllSwitchingTimesRequest } from '@/services/ObjectService'
import {_weekdays, validateWeekday, showLoadingLayer, hideLoadingLayer} from "@/services/Globals";
import Swal from "sweetalert2";
import CircuitDiagram from "@/components/CircuitDiagram.vue";
import Timetable from "@/components/Timetable.vue";
import { stringToDate } from "@/services/Globals";

@Options({
	components: {CircuitDiagram, Timetable}
})
export default class SwitchingTimes extends Vue {
	headline = "Übersicht ihrer Schaltpläne"
	projects: any[] = []
	weekdays = _weekdays
	
	get userRights():any {
		return this.$store.getters.rights
	}
	
	configSwitchingTimes() {
		this.$router.push({ name: 'SwitchingTimesProjects' })
	}
	
	stringToDate(date: string, lang: string) {
		return stringToDate(date, lang)
	}
	
	goToSystemSwitchingTimes(objectId: any, systemId: any) {
		this.$router.push({ name: 'ObjectSystemSwitchingTimes', params: { id: objectId, system: systemId } })
	}
	
	showInterval(interval: any) {
		Swal.fire({
			title: 'Interval',
			text: `Von ${ interval.validateTime.start.hours }:${ interval.validateTime.start.minutes } Bis ${ interval.validateTime.end.hours }:${ interval.validateTime.end.minutes }`,
			showConfirmButton: true,
			showCancelButton: false,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-accent'
			},
			cancelButtonText: 'Bearbeiten'
		}).then(() => {
			//
		})
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAllSwitchingTimesRequest().then((res: any) => {
			// let projects = []
			if ( res.result ) {
				console.log('result', res.result)
				res.result.map((project: any) => {
					let systems: any[] = []
					if ( project.systems ) {
						project.systems.map((system: any) => {
							console.log('system: ', system)
							let interval: any[] = []
							let onTimes: any[] = []
							let offTimes: any[] = []
							
							if ( system.interval && system.interval.length > 0 ) {
								system.interval.map((time: any) => {
									const validateTime = validateWeekday(time[0], time[1])
									interval.push({ validateTime, time })
								})
							}
							
							if ( system.on && system.on.length > 0 ) {
								system.on.map((on: any) => {
									const validateTime: any = validateWeekday(on, 0)
									onTimes.push({ validateTime, on })
								})
							}
							
							if ( system.off ) {
								system.off.map((off: any) => {
									const validateTime: any = validateWeekday(off, 0)
									offTimes.push({ validateTime, off })
								})
							}
							let systemData = system
							systemData.interval = interval
							systemData.on = onTimes
							systemData.off = offTimes
							
							systems.push(systemData)
						})
					}
					let projectData = project
					projectData.systems = systems
					
					this.projects.push(projectData)
				})
			}
			
			hideLoadingLayer()
		})
	}
}
