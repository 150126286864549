
import {Options, Vue} from "vue-class-component";
import TimeRangeSLider from "@/components/TimeRangeSLider.vue";
import {
	getPresenceTimesRequest,
	getPresenceTimesUsagesRequest,
	savePresenceTimesRequest
} from "@/services/PresenceService";
import Swal from "sweetalert2";
import {getInterventionTypesRequest} from "@/services/InterventionService";

@Options({
	components: {
		TimeRangeSLider
	}
})
export default class PresenceTimes extends Vue {
	form = [
		{ name: "Montag" , start: "00:00", end: "24:00"},
		{ name: "Dienstag" , start: "00:00", end: "24:00"},
		{ name: "Mittwoch" , start: "00:00", end: "24:00"},
		{ name: "Donnerstag" , start: "00:00", end: "24:00"},
		{ name: "Freitag" , start: "00:00", end: "24:00"},
		{ name: "Samstag" , start: "00:00", end: "24:00"},
		{ name: "Sonntag" , start: "00:00", end: "24:00"},
	]
	usages: any = []
	interventionTypes: any = {}
	timeRangeConf: any = {
		0: { name: "ganztätig" },
		1: { name: "innerhalb" },
		2: { name: "außerhalb" },
	}
	
	get objectId() {
		return this.$route.params.id
	}
	
	saveTimes() {
		const data = {
			objectId: this.objectId,
			days: this.form
		}
		savePresenceTimesRequest(data).then((res: any) => {
			if(res.result && res.status == "OK") {
				Swal.fire({
					icon: 'success',
					title: 'Zeiten erfolgreich gespeichert',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
			}
		})
	}
	
	beforeMount() {
		getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
		getPresenceTimesRequest({objectId: this.objectId}).then((res: any) => {
			if ( res.result ) {
				res.result.map((item: any) => {
					console.log(item)
					const index = parseInt(item.day)
					this.form[index].start = item.start
					this.form[index].end = item.end
				})
			}
		})
		
		getPresenceTimesUsagesRequest({ id: this.objectId }).then((res: any) => {
			if ( res.result ) {
				res.result.map((item: any) => {
					console.log(item)
					this.usages.push(item)
				})
			}
		})
	}
}
