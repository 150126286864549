
import {Options, Vue} from "vue-class-component"

@Options({
	props: {
		max: Number,
		count: Number,
		size: {
			type: Number,
			default: 30
		}
	}
})
export default class LightDonutChart extends Vue {

}
