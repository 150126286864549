
import { getAlertByIdRequest } from "@/services/AlertService"
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals"
import { Vue } from "vue-class-component"

// require('./stream.js')

export default class AlertDetailPoVi extends Vue {
	headline = 'Alarm - Details'
	details: any =  {}
	reason = ''
	triggeredBy: any = {}
	
	get alertId() {
		return this.$route.params.id
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAlertByIdRequest(this.alertId).then((res: any) => {
			if ( res.status == "OK" && res.result ) {
				this.details = res.result.details
				this.reason = res.result.reason
				this.triggeredBy = res.result.triggeredBy
			}
			
			hideLoadingLayer()
		})
	}
	
	
}
