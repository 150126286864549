import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'

export function getTicketsRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Ticket/getTickets',
            method: "POST",
            data: {  },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getTicketByIdRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Ticket/getTicketById',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function responseToTicketRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Ticket/responseToTicket',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function sendTicketRequest(data: any = {}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Ticket/newTicket',
            method: "POST",
            data: data || {},
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}