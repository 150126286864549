
import {Options, Vue} from "vue-class-component";
import StepWizard from "@/components/StepWizard.vue";
import Interventions from "@/views/basic/Intervention/Interventions.vue";
import {
	getInterventionTypesRequest,
	getSystemInterventionTypesRequest
} from "@/services/InterventionService";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";

@Options({
	components: { StepWizard, Interventions }
})
export default class InterventionsByInterventionType extends Vue {
	interventionTypeConfig: any = {}
	interventionTypes: any = []
	activeStep: any = 0
	steps: any = {}
	
	get objectId() {
		return this.$route.params.id
	}
	
	get systemId() {
		return this.$route.params.system
	}
	
	get typeId():any {
		return this.$route.params.type
	}
	
	setActiveTab(data: any) {
		this.activeStep = parseInt(data)
		
		this.$router.push({ name: 'SystemInterventionTypeInterventions', params: { id: this.objectId, system: this.systemId, type: this.activeStep } })
	}
	
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypeConfig = res.result
		})
		
		await getSystemInterventionTypesRequest(this.systemId).then((res: any) => {
			if ( res.status === 'OK' ) {
				this.interventionTypes = res.result
				
				res.result.map((item: any) => {
					this.steps[item] = { name: this.interventionTypeConfig[item].name, icon: this.interventionTypeConfig[item].icon, done: false }
				})
				
				console.log('keys',Object.keys(this.steps)[0])
				
				hideLoadingLayer()
			}
		})
		
		this.activeStep = parseInt(Object.keys(this.steps)[0])
		if ( this.typeId ) {
			this.activeStep = parseInt(this.typeId)
		}
	}
}
