
import { Vue } from "vue-class-component";
import {getObjectContactPersonLogsRequest} from "@/services/ObjectService";
import moment from "moment";

export default class ObjectContactPerson extends Vue {
	headline = "Projektansprechpartner Logs"
	logs: any = []
	moment = moment
	
	getLogs() {
		getObjectContactPersonLogsRequest().then((res: any) => {
			if ( res.status == "OK" && res.result.length > 0 ) {
				this.logs = res.result
			}
		})
	}
	
	beforeMount() {
		this.getLogs()
	}
}
