
import { getAlertByIdRequest } from "@/services/AlertService"
import {hideLoadingLayer, showLoadingLayer } from "@/services/Globals"
import { Vue} from "vue-class-component"

// require('./stream.js')

export default class AlertReason extends Vue {
	headline = 'Auslösegrund'
	details: any =  {}
	reason = ''
	videos: any = []
	
	get alertId() {
		return this.$route.params.id
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAlertByIdRequest(this.alertId).then((res: any) => {
			if ( res.status == "OK" && res.result ) {
				this.details = res.result.details
				this.reason = res.result.reason
				
				const videos = res.result.details.videos ? res.result.details.videos : null
				
				if ( videos !== null ) {
					for (let video of videos) {
						console.log(video)
						let url = ""
						if ( video.includes("/getFile.php", 0) ) {
							url = this.$getFileUrl + video.substr(12)
						} else {
							url = this.$getFileUrl + video
						}
						console.log(url)
						this.videos.push(url)
					}
				}
			}
			
			hideLoadingLayer()
		})
	}
	
	
}
