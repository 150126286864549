
import { Vue, Options } from "vue-class-component"
import EditInterventionNumber from "@/components/EditInterventionNumber.vue";
import Swal from "sweetalert2";
import {changeInterventionNumberRequest} from "@/services/UserService";

@Options({
	components: { EditInterventionNumber }
})
export default class ChangePhoneNumber extends Vue {
	
	onSuccess(data: any) {
		// early return when already verified (because of Festnetz number)
		if ( data.verified) {
			return Swal.fire({
				icon: "success",
				title: "Erfolgreich",
				html: `
						<h5>Ihre Telefonnummer wurde geändert.</h5>
						<span>Möchten Sie diese Nummer als Interventionskontakt verwenden ?</span>
					`,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-secondary'
				},
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "<i class='icon-check2'></i> Ja, diese Nummer verwenden",
				cancelButtonText: "<i class='icon-cross'></i> Nein, nicht ändern"
				// timer: 2000
			}).then((result: any) => {
				if ( result.isConfirmed ) {
					// call to api
					changeInterventionNumberRequest(data.type).then(() => {
						this.$router.push({ name: 'ChangePhoneNumber' })
					})
				} else if ( result.isDismissed || result.isDenied ) {
					this.$router.push({ name: 'ChangePhoneNumber' })
				}
				
			})
		}
		
		Swal.fire({
			icon: "success",
			title: "Erfolgreich",
			text: "Sie erhalten in kürze eine SMS mit einem Bestätigungslink sowie einem PIN",
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn btn-primary'
			},
			timer: 2000
		}).then((result: any) => {
			if ( result.isDismissed || result.isConfirmed ) {
				this.$router.push({ name: 'ConfirmPhoneValidation', params: { hash: data.hash } })
			}
		})
	}
}
