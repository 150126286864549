
import { Vue } from 'vue-class-component'
import {getAllLocationMapsRequest} from "@/services/ObjectService";
import { conf } from '@/config/conf'
import {hideLoadingLayer, showLoadingLayer } from '@/services/Globals';

export default class LocationMaps extends Vue {
	projects: any[] = []
	baseFileUrl = conf.baseFileUrl
	
	get user() {
		return this.$store.getters.user
	}
	
	showLocationMap(image: string, name: any) {
		const images = []
		images.push({
			src  : image,
			type: 'image',
			opts : {
				caption : name,
				thumb   : image
			}
		})
		// @ts-ignore
		$.fancybox.open(images, {
			loop : false,
			image: {
				preload: true
			},
			buttons: [
				"zoom",
				//"share",
				"slideShow",
				"fullScreen",
				"download",
				"thumbs",
				"close"
			],
		});
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAllLocationMapsRequest().then((res: any) => {
			if ( res.result ) this.projects = res.result
			
			hideLoadingLayer()
		})
	}
}
