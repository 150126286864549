
import {Options, Vue} from "vue-class-component";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {getInterventionTypesRequest, getSystemInterventionTypesRequest} from "@/services/InterventionService";
import StepWizard from "@/components/StepWizard.vue";
import InterventionContacts from "@/views/basic/Intervention/InterventionContacts.vue";
import {checkForNotSynced} from "@/services/BackgroundTasks";

@Options({
	components: {
		StepWizard, InterventionContacts
	}
})
export default class ContactsByInterventionType extends Vue {
	interventionTypeConfig: any = {}
	interventionTypes: any = []
	activeStep: any = 0
	steps: any = {}
	
	get objectId() {
		return this.$route.params.id
	}
	
	get systemId() {
		return this.$route.params.system
	}
	
	get typeId():any {
		return this.$route.params.type
	}
	
	saved() {
		console.log(this.typeId)
		if ( this.typeId ) {
			this.$router.back()
		}
		
		checkForNotSynced()
	}
	
	step(data: any) {
		this.activeStep = parseInt(data.stepIndex)
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypeConfig = res.result
		})
		
		await getSystemInterventionTypesRequest(this.systemId).then((res: any) => {
			if ( res.status === 'OK' ) {
				this.interventionTypes = res.result
				
				res.result.map((item: any) => {
					this.steps[item] = { name: this.interventionTypeConfig[item].name, icon: this.interventionTypeConfig[item].icon, done: false }
				})
				
				console.log('keys',Object.keys(this.steps)[0])
				
				hideLoadingLayer()
			}
		})
		
		this.activeStep = parseInt(Object.keys(this.steps)[0])
		if ( this.typeId ) {
			this.activeStep = parseInt(this.typeId)
		}
	}
}
