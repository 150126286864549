
import { Vue } from "vue-class-component";
import {resetPasswordRequest} from "@/services/AuthService";

export default class LoginData extends Vue {
	frm: any = {
		email: { value: '' }
	}
	resetSuccess = false
	
	resetPassword() {
		resetPasswordRequest(this.frm.email.value).then((res: any) => {
			if ( res.status == 'OK' ) {
				this.resetSuccess = true
			}
		})
	}
}
