
import {Options, Vue} from "vue-class-component";
import {
	getInterventionByIdRequest,
	getInterventionTypesRequest,
	getSystemInterventionContactsRequest,
	saveSystemInterventionRequest,
	getAvailableContactsForIntTypeRequest
} from "@/services/InterventionService";
import { getContactInterventionObjectRequest } from "@/services/ObjectService";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {checkForNotSynced} from "@/services/BackgroundTasks";

@Options({
	components: { draggable }
})
export default class EditIntervention extends Vue {
	headline = "Intervention"
	form: any = {
		type: { name: "Intervention festlegen", value: 0},
		timeRange: { name: "Zeitraum", value: 0},
		contactOrder: []
	}
	interventionTypeConfig: any = {}
	availableContactUnits: any = []
	contactUnits: any[] = []
	interventionContacts: any = []
	objectContactPersonIntervention : any = {}
	
	get user() {
		return this.$store.getters.user
	}

	get interventionType(){
		return this.$route.params.type
	}
	
	get systemId() {
		return this.$route.params.system
	}

	get objectId() {
		return this.$route.params.id
	}
	
	get interventionId() {
		return this.$route.params?.intervention ? this.$route.params.intervention : null
	}

	shouldDisableInterventionChanges() {
		if (!this.user.rights.interventions) {
			return true;
		}
		if (this.user.rights.interventions) {
			return this.user.rights.interventions.write
		}
	}

	shouldDisableSelections() {
		if (this.form.contactOrder.length > 0 && this.form.contactOrder.includes("keiner")) {
			return true;
		} else {
			return false;
		}
	}

	shouldDisableKeine(element: any) {
		if (element === 'keiner' && this.form.contactOrder.length > 0) {
			return true;
		}
		return false;
	}
	
	goToContacts() {
		this.$router.push({ name: "ObjectSystemContactInterventionTypes", params: {system: this.systemId, type: this.interventionType} })
	}

	goToObjectContact() {
		this.$router.push({ name: "ObjectContactPersonIntervention", params: {id: this.$route.params.id}})
	}
	
	checkElement(element: any){
		console.log(element)
		const index = this.availableContactUnits.indexOf(element)
		const elem = this.availableContactUnits.splice(index,1)
		this.form.contactOrder.push(elem[0])
	}
	
	removeElement(element: any){
		console.log(element)
		const index = this.form.contactOrder.indexOf(element)
		const elem = this.form.contactOrder.splice(index,1)
		this.availableContactUnits.push(elem[0])
	}
	
	saveIntervention(force: any = false) {
		let intervention = {
			interventionType: this.form.type.value,
			systemId: this.systemId,
			timeRange: this.form.timeRange.value,
			contactOrder: this.form.contactOrder,
			objectId: this.objectId
		}
		
		console.log('hallo', force, typeof force)
		
		if ( force ) Object.assign(intervention, { force: true })
		
		
		if ( this.interventionId && this.interventionId != null ) Object.assign(intervention, { id: this.interventionId })
		
		saveSystemInterventionRequest(intervention).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Datensatz erfolgreich gespeichert';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			if ( res.status == 'OK' ) {
				Swal.fire({
					icon: icon,
					title: message
				})
				
				checkForNotSynced()
				
				return this.$router.back()
			} else {
				let title = "";
				if ( this.form.timeRange.value == 0 ) {
					title = "Sie haben bereits den Zeitraum inner -/ außerhalb gewählt"
				} else {
					title = "Sie haben bereits den Zeitraum ganztätig gewählt"
				}
				
				Swal.fire({
					icon: 'warning',
					title: title,
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn btn-primary",
						cancelButton: "btn btn-secondary",
					},
					showCancelButton: true,
					confirmButtonText: "Speichern und überschreiben",
					cancelButtonText: "Abbrechen"
				}).then((result: any) => {
					if ( result.isConfirmed ) {
						this.saveIntervention(true)
					}
				})
			}
		})
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getSystemInterventionContactsRequest(this.systemId, this.interventionType).then((res: any) => {
			if ( res.result && res.result.length > 0 ) {
				this.interventionContacts = res.result
			}
		})

		await getContactInterventionObjectRequest(this.objectId).then((res: any) => {
			if ( res.result ) {
				this.objectContactPersonIntervention = res.result.details
			}
		})
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypeConfig = res.result
		})
		
		await getSystemInterventionContactsRequest(this.systemId, this.interventionType).then((res: any) => {
			this.contactUnits = res.result
		})
		
		
		const promise1 = new Promise((resolve, reject) => {
			getAvailableContactsForIntTypeRequest(this.systemId, this.interventionType).then((res: any)=>{
				let availableContactUnits: any = (res as any).result;
				if (!availableContactUnits || availableContactUnits.length == 0) {
					const types: any = this.interventionTypeConfig;
					const ret: any = [];
					const type = this.form.type.value
					if ( types[type]?.contactPerson.available ) ret.push('contactPerson')
					if ( types[type]?.fireDepartment.available ) ret.push('fireDepartment')
					if ( types[type]?.police.available ) ret.push('police')
					if ( types[type]?.rescueService.available ) ret.push('rescueService')
					if ( types[type]?.securityGuard.available ) ret.push('securityGuard')
					if ( types[type]?.speaker.available ) ret.push('speaker')
					if ( types[type]?.siren.available ) ret.push('siren')
					if ( types[type]?.object.available ) ret.push('object')
					if ( types[type]?.keiner.available ) ret.push('keiner')
					availableContactUnits = ret;
				}
				resolve(availableContactUnits);
			}).catch(()=>{
				reject();
			})
		});
		let promise2: any;
		if ( this.interventionId ) {
			// eslint-disable-next-line
			promise2 = new Promise((resolve, reject) => {
				getInterventionByIdRequest(this.interventionId).then((res: any) => {
				if ( !res.result ) {
					resolve([])
					return false;
				}

				this.form.timeRange.value = res.result.timeRange;
				resolve(res.result.contactOrder)
				}).catch(()=>{
					reject();
				})
			});
		}

		Promise.all([promise1, promise2]).then((values) => {
			console.log(values)
			this.availableContactUnits = values ? values[0] : [];
			const contactOrder = values ? (values[1] ? values[1]: []) : [];
			console.log(contactOrder)
			contactOrder.map((item: any) => {
				const index = this.availableContactUnits.indexOf(item)
				const value = this.availableContactUnits[index]
				this.availableContactUnits.splice(index, 1)
				if ( value ) {
					this.form.contactOrder.push(value)
				}
			})

		});
		this.form.type.value = this.interventionType
		
		hideLoadingLayer()
	}
	
	mounted() {
		//
	}
}
