
import { Vue } from "vue-class-component"
import {getAccountsRequest, unlinkAccountRequest} from "@/services/UserService";
import { _userStatus } from "@/services/Globals"
import { switchAccountRequest } from "@/services/AuthService";
import Swal from "sweetalert2";

export default class LinkedAccounts extends Vue {
	headline = 'Accounts verwalten'
	accounts: any[] = []
	userStatus = _userStatus
	
	get user() {
		return this.$store.getters.user
	}
	
	changeAccount(user: any, evt: any) {
		console.log(evt)
		for (let elem of evt.path) {
			console.log(elem.tagName)
			if ( elem.tagName == 'BUTTON' ) {
				const attr = elem.getAttribute('data-toggle')
				if ( attr == "dropdown" ) return false
			}
		}
		
		switchAccountRequest(user.id, user.userType).then((res: any) => {
			if ( res.status === 'ERROR' ) {
				return Swal.fire({
					title: 'Fehler',
					text: 'Anmeldung Fehlgeschalgen',
					icon: 'error',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			}
			
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			window.location.reload()
			
			// this.$router.push({ name: "Dashboard" })
		})
	}
	
	showDropdown(evt: any) {
		const btn = evt.target
		const btnGroup = btn.parentNode.closest('.btn-group')
		const dropdown = btnGroup.querySelector('.dropdown-menu')
		
		dropdown.classList.add('show')

	}
	
	linkAccount() {
		this.$router.push({name: 'LinkAccount'})
	}
	
	unlinkAccount(acc: any) {
		Swal.fire({
			title: 'Achtung',
			html: `
				<div class="d-flex flex-column">
					<span>Wollen Sie die Verknüpfung zu dem Account</span>
					<b class="py-2">"${acc.email}"</b>
					<span>wirklich trennen ?</span>
					<span class="font-italic mt-2"><i class="fa fa-exclamation"></i> Wenn Sie die Accounts trennen, können Sie nicht mehr zu dem Account wechseln.</span>
				</div>
			`,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn bg-light'
			},
			confirmButtonText: '<i class="icon-check2"></i> Ja, jetzt trennen',
			cancelButtonText: '<i class="icon-cross"></i> Nein, lieber nicht',
			showCancelButton: true
		}).then((res: any) => {
			if ( res.isConfirmed ) {
				unlinkAccountRequest(acc.hash).then((res: any) => {
					if ( res.status === 'ERROR' ) {
						return Swal.fire({
							title: 'Fehler',
							text: 'Account konnte nicht gelöscht werden !',
							buttonsStyling: false,
							customClass: {
								confirmButton: 'btn bg-danger'
							},
							icon: 'error'
						})
					}
					
					window.location.reload()
				})
			}
		})
	}
	
	beforeMount() {
		getAccountsRequest().then((res: any) => {
			let accounts: any = []
			if ( res.status === 'OK' && res.result.length ) {
				res.result.map((acc: any) => {
					switch (acc.accountType) {
						case "1":
							acc.icon = 'icon-home'
							break
						case "2":
							acc.icon = 'icon-office'
							break
						default:
							acc.icon = 'icon-user'
					}
					accounts.push(acc)
				})
			}
			this.accounts = accounts
		})
	}
	
}
