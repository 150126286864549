
import {Options, Vue} from "vue-class-component";
import FilterBar from "@/components/FilterBar.vue";
import {getFilterRequest, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import { getComponentsGroupsRequest } from "@/services/ComponentService";

@Options({
	components: { FilterBar }
})
export default class ComponentGroups extends Vue {
	headline = "Komponentengruppen"
	groups: any[] = []
	filter: any = {}
	filterArr: any = []
	search = ""
	
	get objectId() {
		return this.$route.params.id || null
	}
	
	editGroup(group: any) {
		this.$router.push({ name: "ObjectComponentGroupDetails", params: { group: group.id } })
	}
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		const data: any = {
			filter: args.filterArray,
			search: args.search
		}
		
		if ( this.objectId ) data.objectId = this.objectId
		
		this.getComponentGroups(data)
	}
	
	getComponentGroups(args = {}) {
		getComponentsGroupsRequest(args).then((res: any) => {
			console.log(res)
			hideLoadingLayer()
			
			if ( !res.result ) return false
			
			this.groups = res.result
		})
	}
	
	created() {
		showLoadingLayer()
		
		getFilterRequest('componentGroups').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			
			this.filter = res.result.filter
		})
		
		this.getComponentGroups()
	}
}
