
import { Vue } from "vue-class-component";
import {getProjectsOverviewRequest} from "@/services/ObjectService";

export default class PresenceTimesObjects extends Vue {
	headline = "Anwesenheitszeiten konfigurieren"
	projects: any = []
	
	nextStep(projectId: any) {
		this.$router.push({ name: 'ObjectPresenceTimes', params: { id: projectId } })
	}
	
	beforeMount() {
		const data = {
			filter: {
				status: [1,20]
			}
		}
		getProjectsOverviewRequest(data).then((res: any) => {
			if ( res.status === "OK" ) {
				this.projects = res.result.projekte
			}
		})
	}
}
