
import { Vue } from "vue-class-component"
import { getSystemsByInterventionTypeRequest } from "@/services/ObjectService";
import Swal from "sweetalert2";
import { takeOverInterventionContactsRequest } from "@/services/InterventionService";

export default class InterventionTakeOver extends Vue {
	headline = 'Kontakte übernehmen'
	systems: any[] = []
	
	chooseSystem(systemId: any) {
		console.log(systemId)
		Swal.fire({
			title: "Diese Anlage übernehmen?",
			confirmButtonText: 'Übernehmen',
			cancelButtonText: 'Abbrechen',
			showCancelButton: true,
			buttonsStyling: false,
			customClass: {
				cancelButton: 'btn btn-secondary',
				confirmButton: 'btn btn-primary'
			},
			icon: 'question'
		}).then((res: any) => {
			if ( res.isConfirmed ) {
				this.takeOver(systemId)
			} else {
				// do nothing
			}
		})
	}
	
	get interventionType() {
		return this.$route.params.type
	}
	
	get systemId() {
		return this.$route.params.system
	}
	
	get objectId() {
		return this.$route.params.id
	}
	
	takeOver(systemId: any) {
		takeOverInterventionContactsRequest(systemId, this.systemId, this.interventionType).then((res: any) => {
			if ( res.result ) {
				this.$router.back()
			}
		})
	}
	
	beforeMount() {
		getSystemsByInterventionTypeRequest(this.interventionType, this.objectId).then((res: any) => {
			if ( res.status === 'OK' && res.result.length > 0 ) {
				
				const findSelfSystemId = res.result.findIndex((elem: any) => elem.anlage_id == this.systemId)
				res.result.splice(findSelfSystemId, 1)
				
				this.systems = res.result
			}
		})
	}
}
