
import { Vue } from "vue-class-component"
import {getAlertByIdRequest} from "@/services/AlertService";

export default class AlertDetail extends Vue {
	headline = 'Alarmdetails'
	alert: any = {}
	
	
	beforeMount() {
		let alertId = this.$route.params.id
		getAlertByIdRequest(alertId).then((res: any) => {
			if ( res.result ) this.alert = res.result
		})
	}
}
