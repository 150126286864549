
import { Vue, Options } from "vue-class-component";
import Interventions from "@/views/basic/Intervention/Interventions.vue";

@Options({
	components: {Interventions},
	props: {
		start: String,
		end: String
	},
	watch: {
		start(val) {
			const end = this.calcX(this.end)
			const start = this.calcX(val)
			
			this.sliderRange.style.width = (end - start + 15)+"px"
			this.sliderRange.style.left = start+"px"
			
			this.toggleLeft.style.left = start+"px";
			this.toggleLeftX = start;
		},
		end(val) {
			const start = this.calcX(this.start)
			const end = this.calcX(val)
			
			this.sliderRange.style.width = (end - start + 15)+"px"
			this.sliderRange.style.left = start+"px"
			
			this.toggleRightX = end;
			this.toggleRight.style.left = end+"px";
		}
	}
})
export default class TimeRangeSLider extends Vue {
	min = 0
	max = 168
	start: any
	end: any
	slider: any
	sliderWidth = 0
	sliderRange: any
	toggleLeft: any
	toggleLeftX = 0
	toggleLeftValue: number | string = 0
	toggleRight: any
	toggleRightX = 0
	toggleRightValue: number | string = 0
	selectedToggle: any
	startX = 0
	
	changeStart(evt: any) {
		const end = this.calcX(this.end)
		const start = this.calcX(evt.target.value)
		
		if ( start >= end ) {
			this.$emit('update:start', this.end)
			return false
		}
		
		this.$emit('update:start', evt.target.value)
		
		this.sliderRange.style.width = (end - start + 19)+"px"
		this.sliderRange.style.left = start+"px"
		
		this.toggleLeft.style.left = start+"px";
		this.toggleLeftX = start;
	}
	
	changeEnd(evt: any) {
		this.$emit('update:end', evt.target.value)
		
		const start = this.calcX(this.start)
		const end = this.calcX(this.end)
		
		this.sliderRange.style.width = (end - start + 19)+"px"
		this.sliderRange.style.left = start+"px"
		
		this.toggleRightX = end;
		this.toggleRight.style.left = end+"px";
	}
	
	onStart(evt: any) {
		let event = evt
		
		if ( evt.touches ){
			event = evt.touches[0]
		}
		
		if ( event.target == this.toggleLeft ) {
			this.toggleLeftX = this.toggleLeft.offsetLeft
			
			this.startX = event.pageX - this.toggleLeftX
		} else {
			this.toggleRightX = this.toggleRight.offsetLeft
			
			this.startX = event.pageX - this.toggleRightX
		}
		
		this.selectedToggle = event.target
		
		document.addEventListener('mousemove', this.onMove)
		document.addEventListener('mouseup', this.onStop)
		document.addEventListener('touchmove', this.onMove)
		document.addEventListener('touchend', this.onStop)
		
		this.toggleLeft.style.transition = "unset";
		this.toggleRight.style.transition = "unset";
		this.sliderRange.style.transition = "unset";
	}
	
	onMove(evt: any) {
		evt.preventDefault()
		let event = evt
		
		if ( evt.touches ){
			event = evt.touches[0]
		}
		
		let x = event.pageX - this.startX
		
		if (this.selectedToggle === this.toggleLeft)
		{
			if(x > (this.toggleRight.offsetLeft - this.selectedToggle.offsetWidth + 19))
			{
				x = (this.toggleRight.offsetLeft - this.selectedToggle.offsetWidth + 19)
			}
			else if(x < 0)
			{
				x = 0;
			}
			
			let time: any = 24 / (this.sliderWidth - 19) * x
			
			const rest = time % 0.25
			if ( Math.round(rest/0.25) == 1 ) { // auf 0.25 aufrunden
				time = time + (0.25 - rest)
			} else { // rest betrag abziehen
				time = time - rest
			}
			
			x = time / 24 * (this.sliderWidth - 19)
			
			this.selectedToggle.style.left = x + 'px';
			
			this.$emit('update:start', this.calcTime(x))
			
			this.toggleLeftX = x
		} else if (this.selectedToggle === this.toggleRight) {
			if(x < (this.toggleLeft.offsetLeft + this.toggleLeft.offsetWidth - 19))
			{
				x = (this.toggleLeft.offsetLeft + this.toggleLeft.offsetWidth - 19)
			}
			else if(x > this.sliderWidth - 19)
			{
				x = this.sliderWidth - 19;
			}
			
			let time: any = 24 / (this.sliderWidth - 19) * x
			
			const rest = time % 0.25
			if ( Math.round(rest/0.25) == 1 ) { // auf 0.25 aufrunden
				time = time + (0.25 - rest)
			} else { // rest betrag abziehen
				time = time - rest
			}
			
			x = time / 24 * (this.sliderWidth - 19)
			
			this.selectedToggle.style.left = x + 'px';
			
			this.$emit('update:end', this.calcTime(x))
			
			this.toggleRightX = x
		}
		
		this.sliderRange.style.width = (this.toggleRightX - this.toggleLeftX + 19)+"px"
		this.sliderRange.style.left = this.toggleLeftX+"px"
	}
	
	onStop(evt: any) {
		document.removeEventListener('mousemove', this.onMove)
		document.removeEventListener('mouseup', this.onStop)
		document.removeEventListener('touchmove', this.onMove)
		document.removeEventListener('touchend', this.onStop)
		
		this.selectedToggle = null
	}
	
	calcTime(x: number): string {
		const scale = x / (this.sliderWidth - 19)
		const time = scale * 24
		
		let hours: any = Math.floor(time)
		let minutes:any = Math.round((time - hours) * 60)
		// fix issue if minutes will be 60
		if ( minutes == 60 ) {
			hours = hours + 1
			minutes = 0
		}
		
		hours = hours < 10 ? '0'+hours : hours
		minutes = minutes < 10 ? '0'+minutes : minutes
		
		return hours+':'+minutes
	}
	
	calcX(time: any) {
		const timeParts = time.split(':')
		const hours = parseInt(timeParts[0])
		const minutes = parseInt(timeParts[1])
		const ret = minutes > 0 ? (this.sliderWidth - 19) / 24 * (hours + (minutes / 60)) : (this.sliderWidth - 19) / 24 * hours
		
		return ret
	}
	
	resetToggles() {
		const slider: any = this.$refs.slider
		
		const start = this.calcX("00:00")
		const end = this.calcX("00:00")
		
		this.sliderRange = slider.querySelector('.slider-range-bar')
		this.sliderRange.style.width = (end - start + 19)+"px"
		this.sliderRange.style.left = start+"px"
		
		this.toggleLeft = slider.querySelector('.slider-toggle.left')
		this.toggleLeft.addEventListener('mousedown', this.onStart)
		this.toggleLeft.addEventListener('touchstart', this.onStart)
		this.toggleLeft.style.left = start+"px";
		this.toggleLeftX = start;
		
		this.toggleRight = slider.querySelector('.slider-toggle.right')
		this.toggleRight.addEventListener('mousedown', this.onStart)
		this.toggleRight.addEventListener('touchstart', this.onStart)
		this.toggleRightX = end;
		this.toggleRight.style.left = end+"px";
		
		this.$emit('update:start', "00:00")
		this.$emit('update:end', "00:00")
	}
	
	mounted() {
		const slider: any = this.$refs.slider
		
		this.slider = slider.querySelector('.slider-bar')
		this.sliderWidth = this.slider.offsetWidth
		
		const start = this.calcX(this.start)
		const end = this.calcX(this.end)
		
		this.sliderRange = slider.querySelector('.slider-range-bar')
		this.sliderRange.style.width = (end - start + 19)+"px"
		this.sliderRange.style.left = start+"px"
		
		this.toggleLeft = slider.querySelector('.slider-toggle.left')
		this.toggleLeft.addEventListener('mousedown', this.onStart)
		this.toggleLeft.addEventListener('touchstart', this.onStart)
		this.toggleLeft.style.left = start+"px";
		this.toggleLeftX = start;
		
		this.toggleRight = slider.querySelector('.slider-toggle.right')
		this.toggleRight.addEventListener('mousedown', this.onStart)
		this.toggleRight.addEventListener('touchstart', this.onStart)
		this.toggleRightX = end;
		this.toggleRight.style.left = end+"px";
		
	}
}
