
import { Vue } from "vue-class-component"
import {getProjectFilesRequest} from "@/services/ObjectService";
import {_fileTypes} from "@/services/Globals";

export default class ObjectFiles extends Vue {
	headline = 'Unterlagen zum Objekt'
	files: any[] = []
	
	fileTypes: any = _fileTypes
	
	showFile(file: any) {
		
		console.log('kekse', file)
		
		let images: any[] = []
		images.push({
			src  : file.target,
			opts : {
				caption : file.name,
				thumb   : file.target,
				type: "iframe"
			}
		})
		// @ts-ignore
		$.fancybox.open(images, {
			loop : false,
			buttons: [
				"zoom",
				//"share",
				"slideShow",
				"fullScreen",
				"download",
				"thumbs",
				"close"
			],
		});
	}
	
	beforeMount() {
		const objectId = this.$route.params.id
		
		getProjectFilesRequest(objectId).then((res: any) => {
			console.log('Bilder: ', res)
			if ( res.status == 'OK' && res.result.length > 0 ) this.files = res.result
		})
	}
}
