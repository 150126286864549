
import { Vue } from 'vue-class-component'
import {getAlertByIdRequest} from "@/services/AlertService";
import {hideLoadingLayer, showLoadingLayer} from '@/services/Globals';
declare let $: any

export default class AlertLocationMaps extends Vue {
	headline = "Lagepläne"
	locationMaps: any[] = []
	
	get alertId(){
		return this.$route.params.id
	}
	
	showLocationMap(image: string, name: any) {
		const images = []
		
		images.push({
			src  : image,
			width: '100px',
			height: '100px',
			opts : {
				caption : name,
				thumb   : image
			}
		})
		
		$.fancybox.open(images, {
			loop : false,
			
			buttons: [
				"zoom",
				//"share",
				"slideShow",
				"fullScreen",
				"download",
				"thumbs",
				"close"
			],
		});
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAlertByIdRequest(this.alertId).then((res: any) =>{
			this.locationMaps = res.result.locationMaps
			
			hideLoadingLayer()
		})
	}
}
