
import { Vue, Options } from 'vue-class-component'
import {getProjectsOverviewRequest} from "@/services/ObjectService";
import ObjectDetailBar from "@/components/Object/ObjectDetailBar.vue";
import ObjectRow from "@/components/Object/ObjectRow.vue";

@Options({
    components: {ObjectRow, ObjectDetailBar}
})
export default class Objects extends Vue {
    headline = 'Projektübersicht'
    statusConfig: any = {
        10: {code: 10, name: "Inaktiv", icon: "fa fa-times"},
        20: {code: 20, name: "Aktiv", icon: "fa fa-check"},
        30: {code: 30, name: "Gesperrt", icon: "fa fa-lock"}
    }
    activeTab = 20
    projects = []
    projectDetail: any = {}
    showProjectDetailBar = false
    
    get filteredProjects() {
        return this.filterProjects(this.projects);
    }
    
    filterProjects(projects: any) {
        let ret: any = {}
        Object.keys(this.statusConfig).map((status: any) => {
            ret[status] = []
        })
        if (projects) {
            projects.map((item: any) => {
                let status = parseInt(item.p_status)
                if (status == 1) status = 10
                if (status == 2) status = 20
                if (status == 30) status = 10
                if (!ret[status]) ret[status] = []
                ret[status].push(item)
            })
        }
        return ret
    }
    
    showProjectDetail(project: any) {
        this.projectDetail = project
        this.showProjectDetailBar = true
    }
    
    closeProjectDetail() {
        this.showProjectDetailBar = false
    }
    
    mounted(){ // when document ready
        getProjectsOverviewRequest().then((res: any) => {
            this.projects = res.result.projekte
        })
    }
}
