
import { Vue } from "vue-class-component";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {getAlertByIdRequest} from "@/services/AlertService";

export default class AlertPreview extends Vue {
	headline = "Alarmvorschau"
	details: any =  {}
	reason = ''
	triggeredBy: any = {}
	videos: any = []
	
	get alertId() {
		return this.$route.params.id
	}
	
	showDetail() {
		this.$router.push({ name: 'AlertOverview', params: { id: this.alertId } })
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getAlertByIdRequest(this.alertId).then((res: any) => {
			if ( res.status == "OK" && res.result ) {
				this.details = res.result.details
				this.reason = res.result.reason
				this.triggeredBy = res.result.triggeredBy
				
				const videos = res.result.details.videos ? res.result.details.videos : null
				
				if ( videos !== null ) {
					for (let video of videos) {

						let url = ""
						if ( video.includes("/getFile.php", 0) ) {
							url = this.$getFileUrl + video.substr(12)
						} else {
							url = this.$getFileUrl + video
						}
						let type = "mp4";
						if (video.includes(".gif")) {
							type = "gif";
						}

						this.videos.push({
							url: url,
							type,
						});
					}
				}
				console.log(this.videos)
			}
			
			hideLoadingLayer()
		})
	}
}
