import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "nav nav-tabs nav-tabs-highlight nav-justified mb-0" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "alert alert-warning alert-styled-left"
}
const _hoisted_5 = {
  key: 1,
  class: "tab-content card card-body border-top-0 rounded-0 rounded-bottom mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InterventionContacts = _resolveComponent("InterventionContacts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, stepIndex) => {
        return (_openBlock(), _createElementBlock("li", {
          key: stepIndex,
          class: "nav-item",
          onClick: ($event: any) => (_ctx.activeStep = stepIndex)
        }, [
          _createElementVNode("a", {
            href: "#justified-top-icon-tab1",
            class: _normalizeClass(["nav-link", {'active':_ctx.activeStep==stepIndex}]),
            "data-toggle": "tab"
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("i", {
                class: _normalizeClass(["d-block mb-1 mt-1", step.icon])
              }, null, 2),
              _createTextVNode(" " + _toDisplayString(step.name), 1)
            ])
          ], 2)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.interventionTypes.length <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Für diese Anlage sind keine Ereignisse zum Konfigurieren hinterlegt. Wenn Sie dazu Fragen haben, wenden Sie sich bitte an Ihren Errichter. "))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.interventionTypes, (type, typeIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: typeIndex,
              class: _normalizeClass(["tab-pane fade", {'show active':type==_ctx.activeStep}])
            }, [
              _createVNode(_component_InterventionContacts, {
                interventionType: parseInt(type),
                onSaved: _ctx.saved
              }, null, 8, ["interventionType", "onSaved"])
            ], 2))
          }), 128))
        ]))
  ]))
}