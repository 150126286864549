
import { Vue } from "vue-class-component";
import {getNotificationConfigRequest, saveNotificationConfigRequest} from "@/services/UserService";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import Swal from "sweetalert2";

export default class Notification extends Vue {
	headline = "Benachrichtigungen"
	notifications: any = {
		realAlert: {
			name: "Echtalarm", checked: {
				app: false,
				email: false
			}
		},
		failAlert: {
			name: "Fehlalarm", checked: {
				app: false,
				email: false
			}
		},
		falseAlert: {
			name: "Falschalarm", checked: {
				app: false,
				email: false
			}
		},
		arming: {
			name: "Scharf / Unscharfschaltung", checked: {
				app: false,
				email: false
			}
		},
		alertPlan: {
			name: "Alarmplan", checked: {
				app: false,
				email: false
			}
		}
	}
	
	get objectId(): any {
		return this.$route.params.id
	}
	
	saveNotificationConfig() {
		const conf = {
			realAlert: {
				app: this.notifications.realAlert.checked.app,
				email: this.notifications.realAlert.checked.email
			},
			failAlert: {
				app: this.notifications.failAlert.checked.app,
				email: this.notifications.failAlert.checked.email
			},
			falseAlert: {
				app: this.notifications.falseAlert.checked.app,
				email: this.notifications.falseAlert.checked.email,
			},
			arming: {
				app: this.notifications.arming.checked.app,
				email: this.notifications.arming.checked.email
			},
			alertPlan: {
				app: this.notifications.alertPlan.checked.app,
				email: this.notifications.alertPlan.checked.email
			}
		}
		
		showLoadingLayer()
		
		saveNotificationConfigRequest(this.objectId, conf).then((res: any) => {
			hideLoadingLayer()
			
			if ( res.status == "OK" ) {
				Swal.fire({
					icon: 'success',
					title: "Änderungen erfolgreich übernommen",
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
			}
		})
	}
	
	getNotificationConfig() {
		showLoadingLayer()
		getNotificationConfigRequest(this.objectId).then((res: any) => {
			// console.log(res)
			this.notifications.realAlert.checked.app = res.result.realAlert.app
			this.notifications.realAlert.checked.email = res.result.realAlert.email
			this.notifications.failAlert.checked.app = res.result.failAlert.app
			this.notifications.failAlert.checked.email = res.result.failAlert.email
			this.notifications.falseAlert.checked.app = res.result.falseAlert.app
			this.notifications.falseAlert.checked.email = res.result.falseAlert.email
			this.notifications.arming.checked.app = res.result.arming.app
			this.notifications.arming.checked.email = res.result.arming.email
			this.notifications.alertPlan.checked.app = res.result.alertPlan.app
			this.notifications.alertPlan.checked.email = res.result.alertPlan.email
			
			hideLoadingLayer()
		})
	}
	
	beforeMount() {
		this.getNotificationConfig()
	}
}
