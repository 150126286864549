
import { Vue } from "vue-class-component";
import {getSwitchingTimeExceptionsLogsRequest} from "@/services/ObjectService";
import moment from "moment";

export default class Exceptions extends Vue {
	headline = "Ausnahmen Logs"
	logs: any = []
	moment = moment
	
	getLogs() {
		getSwitchingTimeExceptionsLogsRequest().then((res: any) => {
			if ( res.status == "OK" && res.result.length > 0 ) {
				this.logs = res.result
			}
		})
	}
	
	beforeMount() {
		this.getLogs()
	}
}
