
import { Vue } from 'vue-class-component'
import { getSocketRooms, requestPrivateChat } from "@/services/Socket";
import {getEmployees} from "@/services/EmplyeeService";

export default class SocketExample extends Vue {
	rooms: any[] = []
	empsDb: any[] = []
	slcEmp: any = ""
	
	get employees() {
		const ret: any[] = []
		this.empsDb.map((item: any) => {
			ret.push({ id: item.id, salutation: item.salutation, firstName: item.firstName, lastName: item.lastName })
		})
		return ret
	}
	
	newChat() {
		requestPrivateChat(this.slcEmp).then((res: any) => {
			console.log(res)
		})
	}
	
	created() {
		this.$socket.on('rooms:all', (data) => {
			console.log(data)
			// these are only the socket ids / room ids the socket is connected with
			// this.rooms = data.rooms
		})
		
		getSocketRooms().then((res: any) => {
			if ( res.result && res.result.length > 0 ) {
				this.rooms = res.result
			}
		})
		
		getEmployees().then((res: any) => {
			if ( res.result && res.result.length > 0 ) {
				this.empsDb = res.result
			}
		})
	}
	
	reloadSocketRooms() {
		this.$socket.emit('rooms:all')
	}
	
	mounted() {
		this.$socket.emit('rooms:all')
	}
}
