
import { Vue } from "vue-class-component";
import moment from "moment";
import {getPresenceTimesLogsRequest} from "@/services/PresenceService";

export default class PresenceTimes extends Vue {
	headline = "Anwesenheitszeiten Logs"
	logs: any = []
	moment = moment
	
	getLogs() {
		getPresenceTimesLogsRequest().then((res: any) => {
			if ( res.status == "OK" && res.result.length > 0 ) {
				this.logs = res.result
			}
		})
	}
	
	beforeMount() {
		this.getLogs()
	}
}
