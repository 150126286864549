
import { impersonateUserRequest } from '@/services/AuthService'
import { Vue } from 'vue-class-component'
import Swal from "sweetalert2";

export default class Impersonate extends Vue {
	frm: any = {
		pin: { name: "PIN", value: "", error: null }
	}
	
	get hash(): any {
		return this.$route.params.hash
	}
	
	confirmImpersonate() {
		
		console.log(this.hash)
		
		const data = {
			hash: this.hash,
			pin: this.frm.pin.value
		}
		
		impersonateUserRequest(data).then((res: any) => {
			if ( res.status === 'ERROR' ) {
				alert('Fehler bei der Anmeldung')
			}
			
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			window.location.href = "/"
		}).catch(() => {
			Swal.fire({
				title: "Fehler",
				text: "Sie konnten sich nicht einloggen",
				icon: "error",
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn bg-danger'
				}
			}).then(() => {
				this.$store.dispatch('resetUser')
				// this.$router.push({ name: 'SignIn' })
			})
		})
	}
	
	beforeMount() {
		this.$store.dispatch('resetUser')
	}
	
	mounted() {
		//
	}
}
