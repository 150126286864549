
import { Vue } from "vue-class-component"

export default class AlertOverview extends Vue {
	headline = 'Alarm Übersicht'
	tabs: any = {
		'reason': {headline: "Auslösegrund / Videos", text: "", class: "border-success text-success", icon: "icon-video-camera3", pathName: "AlertReason", errors: null, disabled: false},
		'routeMap': {headline: "Anfahrtsplan", text: "", class: "border-success text-success", icon: "icon-location4", pathName: "AlertRouteMap", errors: null, disabled: false},
		'locationMap': {headline: "Instalationsplan Melder", text: "", class: "border-success text-success", icon: "icon-map4", pathName: "AlertLocationMaps", errors: null, disabled: false},
		'details': {headline: "Wichtige Fragen / Einsatzdetails", text: "", class: "border-success text-success", icon: "icon-paragraph-left2", pathName: "AlertDetails", errors: null, disabled: false}
	}
	
	get alertId() {
		return this.$route.params.id
	}
	
	showDetails(pathName: string) {
		this.$router.push({name: pathName, params: {id: this.alertId}})
	}
}
