
import { Vue } from "vue-class-component";
import {getProjectByIdRequest} from "@/services/ObjectService";

export default class ObjectOverview extends Vue {
    headline = "Projektdaten"
    objectId: any
    activeTab = 0
    tabs: any = [
        {name: 'Kontakte', icon: 'icon-users', view: 'ObjectSystemContacts', disabled: false},
        {name: 'Schaltzeiten', icon: 'icon-alarm', view: 'ObjectSystemSwitchingTimes', disabled: false},
        {name: 'Lagepläne', icon: 'icon-map4', view: 'Objects', disabled: true},
        {name: 'Komponenten', icon: 'icon-hammer-wrench', view: 'Objects', disabled: true}
    ]
    systems: any = []
    
    
    showDetails(pathName: string) {
        this.$router.push({name: pathName, params: {id: this.objectId}})
    }
    
    filterSystems(args: any) {
        args.systems.map((system: any) => {
            this.systems.push(system)
        })
    }
    
    showSystem(systemId: number | string) {
        this.$router.replace({name: this.tabs[this.activeTab].view, params: {id: this.objectId, system: systemId}})
        // this.$router.push({name: this.tabs[this.activeTab].view, params: {id: this.objectId, system: systemId}})
        
    }
    
    mounted() {
        this.objectId = this.$route.params.id
        
        getProjectByIdRequest(this.$route.params.id).then((res: any) =>{
			
			console.log('result: ', res)
			
            this.filterSystems(res.result)
        })
    }
    
}
