
import { Vue } from "vue-class-component";
import Swal from "sweetalert2";
import {getComponentGroupByIdRequest, saveComponentGroupRequest } from "@/services/ComponentService";
import {getColorListRequest, getIconListRequest, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import router from "@/router";

export default class ComponentGroupDetails extends Vue {
	group: any = {
		name: { name: "Name", value: "", error: null },
		alias: { name: "Alias", value: "", error: null },
		description: { name: "Beschreibung", value: "", error: null },
		icon: { name: "Icon", value: "", error: null },
		class: { name: "Farbe", value: "primary", error: null }
	}
	colorOptions: any[] = []
	iconList: any[] = []
	iconDropdown = false
	
	get groupId () {
		return this.$route.params.group
	}
	
	chooseColor(color: string) {
		this.group.class.value = color
	}
	
	chooseIcon(data: string) {
		this.group.icon.value = data
		this.iconDropdown = false
	}
	
	saveGroup() {
		const data: any = {
			alias: this.group.alias.value,
			description: this.group.description.value,
			icon: this.group.icon.value,
			class: this.group.class.value,
		}
		
		if ( this.groupId ) data.id = this.groupId
		
		saveComponentGroupRequest(data).then((res: any) => {
			if ( res.status !== "OK" ) {
				const errors = res.result.errorFields
				const errorKeys = Object.keys(errors)
				
				Swal.fire({
					title: "Fehler",
					text: res.errorMsg,
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn bg-danger"
					}
				})
				
				errorKeys.map((item: any) => {
					this.group[item].error = errors[item]
				})
				
				return false
			}
			
			const keys = Object.keys(this.group)
			keys.map((item: any) => {
				this.group[item].error = null
			})
			
			Swal.fire({
				icon: "success",
				title: "Erfolgreich",
				text: "Daten wurden erfolgreich übernommen",
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				},
				timer: 2000
			}).then(() => {
				this.$router.back()
			})
		})
	}
	
	created() {
		if ( this.groupId ) {
			showLoadingLayer()
			
			getComponentGroupByIdRequest({id: this.groupId}).then((res: any) => {
				console.log(res)
				if ( !res.result ) return false
				
				this.group.name.value = res.result.name
				this.group.alias.value = res.result.alias
				this.group.description.value = res.result.description
				this.group.icon.value = res.result.icon
				this.group.class.value = res.result.class
				
				hideLoadingLayer()
			})
		}
		
		getIconListRequest().then((res: any) => {
			if ( res.result ) {
				this.iconList = res.result
			}
		})
		
		getColorListRequest().then((res: any) => {
			if ( res.result ) {
				this.colorOptions = res.result
			}
		})
	}
}
