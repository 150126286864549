
import {Options, Vue} from "vue-class-component";
import FilterBar from "@/components/FilterBar.vue";
import {getEmployeeById, getEmployees, saveEmployeeRequest} from "@/services/EmplyeeService";
import {
	getFilterRequest,
	getPhoneCodesRequest,
	hideLoadingLayer,
	showLoadingLayer,
	validatePhoneNumberRequest
} from "@/services/Globals";
import Swal from "sweetalert2";
import {getProjectsOverviewRequest} from "@/services/ObjectService";

declare let $: any

@Options({
	components: { FilterBar }
})
export default class ImportedContacts extends Vue {
	headline = 'Ihre importierten Kontakte'
	users: any[] = []
	filter: any = {}
	filterArr: any = {}
	search = ''
	activeStep = 1
	steps: any = {
		1: { name: 'Nutzerdaten', icon: 'icon-user', done: false },
		2: { name: 'Zugang', icon: 'icon-key', done: false },
		3: { name: 'Projekte', icon: 'icon-key', done: false },
		4: { name: 'Abschluss', icon: 'icon-key', done: false }
	}
	phoneCodes: any = {}
	form: any = {
		entry: false,
		rights: {
			projects: {},
			user: { read: false, write: false },
			invoice: { read: false, write: false },
			contracts: { read: false, write: false },
			company: { read: false, write: false },
			arming: { read: false, write: false }
		},
		user: {
			id: { value: "", error: null },
			salutation: { value: "", error: null },
			company: { value: "", error: null },
			firstName: { value: "", error: null },
			lastName: { value: "", error: null },
			tel: { value: "", error: null },
			areaCodeTel: { value: "", error: null},
			mobil: { value: "", error: null },
			areaCodeMobil: { value: "", error: null},
			interventionNumber: { value: "tel", error: null},
			email: { value: "", error: null }
		}
	}
	
	searchForAreaCodeInNumber(number: string){
		return new Promise((resolve) => {
			const areaCodes = Object.keys(this.phoneCodes)
			areaCodes.map((code: any) => {
				const codeLength = this.phoneCodes[code].length
				if ( number.substring(0,codeLength) == this.phoneCodes[code] ) {
					const slicedNumber = number.slice(codeLength)
					
					return resolve({
						areaCode: code,
						number: slicedNumber
					})
				}
			})
			return resolve({
				areaCode: "DE",
				number: number
			})
		})
	}
	
	resetModal() {
		this.activeStep = 1
		this.steps[1].done = false
		this.steps[2].done = false
		this.steps[3].done = false
		this.steps[4].done = false
	}
	
	validatePhoneNumber(field: string) {
		return new Promise(resolve => {
			const number: string = this.form.user[field].value
			const areaCode = field === "tel" ? this.form.user.areaCodeTel.value : this.form.user.areaCodeMobil.value
			
			if ( number === "" ) return true
			
			validatePhoneNumberRequest(number, areaCode).then((res: any) => {
				if ( res.status === "ERROR" ) {
					this.form.user[field].error = res.result
				} else {
					this.form.user[field].error = null
					const plainNumber = res.result.split(this.phoneCodes[areaCode])[1]
					this.form.user[field].value = plainNumber
				}
				
				resolve('resolved')
			})
		})
	}
	
	go(stepIndex: any) {
		if ( this.activeStep > parseInt(stepIndex) ) return this.activeStep = parseInt(stepIndex)
		
		const prevStepIndex = parseInt(stepIndex) - 1
		if ( parseInt(stepIndex) === 1 ||  this.steps[prevStepIndex].done) {
			return this.activeStep = parseInt(stepIndex)
		}
		
		if ( parseInt(stepIndex) > this.activeStep ) {
			let error = null
			switch (this.activeStep) {
				case 1:
					// improve for something
					if ( this.form.user.firstName.value === "" || this.form.user.lastName.value === "" || (this.form.user.tel.value === "" && this.form.user.mobil.value === "" ) || this.form.user.interventionNumber.value == "" ) {
						error = "Fehler "
						Swal.fire({
							title: "Errors found"
						})
					}
					break
				case 2:
					// improve for something else
					this.steps[2].done = true
					break
				case 3:
					this.steps[3].done = true
					break
				default:
					// do not know
					break
			}
			
			if ( prevStepIndex !== 1 && this.steps[prevStepIndex].done === false) {
				return false
			}
			
			if ( error === null ) {
				this.steps[this.activeStep].done = true
				return this.activeStep = parseInt(stepIndex)
			}
		}
		
		return false
	}
	
	confirmImport() {
		showLoadingLayer()
		
		const rights: any = {
			user: this.form.rights.user,
			invoice: this.form.rights.invoice,
			contracts: this.form.rights.contracts,
			company: this.form.rights.company,
			arming: this.form.rights.arming,
			projects: []
		}
		
		const projectRightKeys = Object.keys(this.form.rights.projects)
		projectRightKeys.map((key: any) => {
			if ( this.form.rights.projects[key].check ) rights.projects.push(key)
		})
		
		const user = {
			id: this.form.user.id.value,
			confirmImport: 1,
			entry: 1,
			salutation: this.form.user.salutation.value,
			company: this.form.user.company.value,
			firstName: this.form.user.firstName.value,
			lastName: this.form.user.lastName.value,
			email: this.form.user.email.value,
			tel: this.form.user.tel.value,
			areaCodeTel: this.form.user.areaCodeTel.value,
			mobil: this.form.user.mobil.value,
			areaCodeMobil: this.form.user.areaCodeMobil.value,
			interventionNumber: this.form.user.interventionNumber.value,
			rights: rights
		}
		
		saveEmployeeRequest(user).then((res: any) => {
			if ( res.status === "OK" ) {
				this.getEmps()
				$('#modal_confirm_import_user').modal('hide')
				
				Swal.fire({
					title: "Erfolgreich",
					text: "Der Nutzer wurde erfolgreich bestätigt",
					icon: "success",
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn btn-primary"
					},
					confirmButtonText: "OK"
				})
			}
			
			hideLoadingLayer()
		})
	}
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		this.getEmps()
	}
	
	getEmpById(id: any) {
		this.resetModal()
		
		getEmployeeById(id).then(async (res: any) =>  {
			if ( res.status === "OK" ) {
				const telParts: any     = await this.searchForAreaCodeInNumber(res.result.tel)
				const mobilParts: any   = await this.searchForAreaCodeInNumber(res.result.mobil)
				
				console.log(telParts, mobilParts)
				
				// form data
				this.form.user.id.value             = res.result.id
				this.form.user.salutation.value     = res.result.salutation
				this.form.user.company.value        = res.result.company
				this.form.user.firstName.value      = res.result.firstName
				this.form.user.lastName.value       = res.result.lastName
				this.form.user.tel.value            = telParts.number
				this.form.user.areaCodeTel.value    = telParts.areaCode
				this.form.user.mobil.value          = mobilParts.number
				this.form.user.areaCodeMobil.value  = mobilParts.areaCode
				this.form.user.email.value          = res.result.email
				
				// access to portal
				this.form.entry                     = res.result.email !== ""
				
				// user right
				this.form.rights.company.read       = res.result.rights.company.read
				this.form.rights.company.write      = res.result.rights.company.write
				this.form.rights.invoice.read       = res.result.rights.invoice.read
				this.form.rights.invoice.write      = res.result.rights.invoice.write
				this.form.rights.contracts.read     = res.result.rights.contracts.read
				this.form.rights.contracts.write    = res.result.rights.contracts.write
				this.form.rights.user.read          = res.result.rights.user.read
				this.form.rights.user.write         = res.result.rights.user.write
				this.form.rights.arming.read        = res.result.rights.arming?.read
				this.form.rights.arming.write       = res.result.rights.arming?.write
				
				this.form.user.interventionNumber.value  = res.result.interventionNumber
				
				// project rights
				for (let projectId of res.result.rights.projects) {
					this.form.rights.projects[projectId].check = true
				}
			}
		})
		
		$('#modal_confirm_import_user').modal('show')
	}
	
	closeImportModal() {
		$('#modal_confirm_import_user').modal('hide')
	}
	
	getEmps(){
		const data: any = {
			search: this.search,
			filter: this.filterArr
		}
		
		data.filter.import = [1]
		
		getEmployees(data).then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					emp.status = parseInt(emp.status)
					this.users.push(emp)
				})
			}
			
			hideLoadingLayer()
		})
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		// get all available phone codes
		await getPhoneCodesRequest().then((res: any) => {
			this.phoneCodes = res.result
		})
		
		getFilterRequest('employees').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			
			this.filter = res.result.filter
		})
		
		getProjectsOverviewRequest().then((res: any) => {
			this.form.rights.projects = {}
			if ( !res.result ) return false
			
			res.result.projekte.map((project: any) => {
				project.check = false
				this.form.rights.projects[project.pid] = project
			})
		})
		
		
		
		this.getEmps()
	}
}
