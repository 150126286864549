
import { Vue } from 'vue-class-component'
import { confirmPasswordResetRequest } from "@/services/AuthService";

export default class ConfirmPasswordReset extends Vue {
	frm: any = {
		username: { value: '', protected: false },
		pin: { value: '', protected: true },
		password: { value: '', protected: true },
		confirm_password: { value: '', protected: true }
	}
	
	get hash(): string {
		return this.$route.params.hash.toString()
	}
	
	confirm() {
		if ( this.frm.username.value === '' || this.frm.password.value === '' || this.frm.confirm_password.value === '' || this.frm.pin.value === '' ) {
			return alert('Bitte alles ausfüllen')
		}
		if ( this.frm.confirm_password.value !== this.frm.password.value ) {
			return alert('Passwörter stimmen nicht überein')
		}
		
		confirmPasswordResetRequest(this.frm.username.value, this.frm.password.value, this.hash, this.frm.pin.value).then((res: any) => {
			
			console.log(res)
			
			if ( res.status === 'ERROR' ) {
				alert('Uuuups!')
			}
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			window.location.href = "/"
		})
	}
}
