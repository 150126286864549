
import { getProjectByIdRequest } from "@/services/ObjectService";
import { Vue } from "vue-class-component";
import {checkSignalsRequest} from "@/services/SignalService";
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";

export default class Systems extends Vue {
	headline = "Anlagen"
	objectId: any
	systems: any = []
	errors: any = {}
	
	filterSystems(args: any) {
		args.systems.map((system: any) => {
			system.errors = null
			if ( this.errors && this.errors.systems?.items[system.anlage_id]?.errors !== null ) {
				system.errors = this.errors.systems.items[system.anlage_id]?.errors
			}
			this.systems.push(system)
		})
	}
	
	showSystem(systemId: number | string) {
		this.$router.push({name: "SwitchingTimesConfigTimes", params: {id: this.objectId, system: systemId}})
	}
	
	beforeMount() {
		this.objectId = this.$route.params.id
		
		checkSignalsRequest('project', this.objectId).then((res: any) => {
			this.errors = res.result
		})
	}
	
	mounted() {
		showLoadingLayer()
		
		this.objectId = this.$route.params.id
		
		getProjectByIdRequest(this.$route.params.id).then((res: any) =>{
			this.filterSystems(res.result)
			
			hideLoadingLayer()
		})
	}
	
}
