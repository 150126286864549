
import { Vue } from "vue-class-component"
import {getAlertByIdRequest} from "@/services/AlertService";
import {hideLoadingLayer, showLoadingLayer } from "@/services/Globals";
declare let google: any

export default class AlertRouteMap extends Vue {
	headline = "Anfahrtsweg"
	map: any
	
	get alertId(){
		return this.$route.params.id
	}
	
	initMap(lat: number, lng: number) {
		
		let zMarkers = [
			['TEXT', lat, lng, 1, '', '', 'blue.png', '901194', '']
		]
		
		var myLatlng = new google.maps.LatLng(lat, lng)
		
		var mapOptions = {
			zoom: 7,
			center: myLatlng,
			mapTypeId: google.maps.MapTypeId.SATELLITE
		}
		
		const mapDiv = document.getElementById('googleMap') as HTMLElement
		
		this.map = new google.maps.Map(mapDiv, mapOptions);
		
		let bounds = new google.maps.LatLngBounds();
		
		for (let i = 0; i < zMarkers.length; i++) {
			let zMarker: any = zMarkers[i];
			new google.maps.Marker({
				position: {lat: zMarker[1], lng: zMarker[2]},
				label: zMarker[5],
				icon: 'https://maps.google.com/mapfiles/ms/micons/' + zMarker[6] + '',
				map: this.map,
				title: zMarker[0],
				zIndex: zMarker[3]
			});
			
			let loc = new google.maps.LatLng(zMarker[1], zMarker[2]);
			bounds.extend(loc);
		}
		
		this.mapGoTo(zMarkers[0][1], zMarkers[0][2])
	}
	
	mapGoTo(lat: any, lng: any) {
		let myLatlng = new google.maps.LatLng(lat, lng);
		this.map.panTo(myLatlng)
		this.map.setZoom(18)
		
		hideLoadingLayer()
	}
	
	mounted() {
		showLoadingLayer()
		
		getAlertByIdRequest(this.alertId).then((res: any) => {
			const geoData = res.result.details.geodatenGenau
			const latLng = geoData.split(',')
			const lat = parseFloat(latLng[0])
			const lng = parseFloat(latLng[1])
			
			google.maps.event.addDomListener(window, 'load', this.initMap(lat, lng));
		})
	}
}
