
import { Vue } from 'vue-class-component'
import { confirmInvitationRequest } from "@/services/AuthService";
import Swal from "sweetalert2";

export default class ConfirmInvitation extends Vue {
	frm: any = {
		username: { value: '', protected: false },
		pin: { value: '', protected: true },
		password: { value: '', protected: true },
		confirm_password: { value: '', protected: true }
	}
	
	get hash(): string {
		return this.$route.params.hash.toString()
	}
	
	confirm() {
		if ( this.frm.username.value === '' || this.frm.password.value === '' || this.frm.confirm_password.value === '' || this.frm.pin.value === '' ) {
			return Swal.fire({
				title: 'Bitte alles ausfüllen',
				icon: 'error',
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn bg-danger'
				}
			})
		}
		if ( this.frm.confirm_password.value !== this.frm.password.value ) {
			return Swal.fire({
				title: 'Passwörter stimmen nicht überein',
				icon: 'error',
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn bg-danger'
				}
			})
		}
		
		confirmInvitationRequest(this.frm.username.value, this.frm.password.value, this.hash, this.frm.pin.value).then((res: any) => {
			
			console.log(res)
			
			if ( res.status === 'ERROR' ) {
				return Swal.fire({
					title: 'Fehler bei der Anmeldung',
					icon: 'error',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			}
			const user = res.result.user
			user.accessToken = res.result.accessToken
			user.refreshToken = res.result.refreshToken
			
			this.$store.dispatch('setUser', user)
			
			window.location.href = "/"
		})
	}
}
