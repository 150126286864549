
import {Options, Vue} from 'vue-class-component'
import {getInvoiceRequest} from "@/services/Invoice";
import moment from 'moment'
import FilterBar from "@/components/FilterBar.vue";
import {getFilterRequest, hideLoadingLayer, showLoadingLayer} from '@/services/Globals';


@Options({
	components: { FilterBar },
	watch: {
		objectId() {
			this.getInvoices()
		}
	}
})
export default class Invoice extends Vue {
	headline = 'Rechnungen'
	invoice: any[] = []
	moment: any = moment
	search = ""
	filter: any =  {}
	filterArr: any = []
	
	get filteredInvoice() {
		return this.filterInvoice(this.invoice)
	}
	
	get objectId():any {
		return this.$route.params.id ? this.$route.params.id : null
	}
	
	filterInvoice(invoice: any){
		let ret:any = { open: [], paid: [], canceled: [], all: [] }
		if ( invoice.length > 0 ) {
			invoice.map((item: any) => {
				if ( parseInt(item.status) === 1 ) { // offen
					item.class = 'accent'
					item.statusName = 'offen'
					ret.open.push(item)
				} else if ( parseInt(item.status) === 2 ) { // bezahlt
					item.class = 'primary'
					item.statusName = 'bezahlt'
					ret.paid.push(item)
				} else { // storniert
					item.class = 'secondary'
					item.statusName = 'storniert'
					ret.canceled.push(item)
				}
				ret.all.push(item)
			})
		}
		return ret
	}
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		this.getInvoices({filter: this.filterArr, search: this.search})
	}
	
	showDetail(invoice: any) {
		window.open(invoice.targetShow, '', '')
	}
	
	getInvoices(args: any = {}) {
		getInvoiceRequest(args).then((res: any) => {
			if ( res.result ) {
				this.invoice = res.result
			}
			
			hideLoadingLayer()
		})
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getFilterRequest('invoices').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			
			this.filter = res.result.filter
		})
		
		this.getInvoices()
	}
	
}
