
import { Vue } from "vue-class-component";
import {_countries} from "@/config/conf";
import {_userStatus, getPhoneCodesRequest, validatePhoneNumberRequest} from "@/services/Globals";
import {
	checkEmailExistRequest,
	getEmployeeGroupsByEmployeeRequest,
	getEmployees,
	saveEmployeeRequest
} from "@/services/EmplyeeService";
import Swal from "sweetalert2";
import {getProjectsOverviewRequest} from "@/services/ObjectService";
import {printContactsOverviewRequest} from "@/services/InterventionService";

export default class NewEmpProcess extends Vue {
	countries = _countries
	user: any = {
		supervisor: { name: 'Vorgesetzter', value: '0' },
		firstName: { name: 'Vorname', value: '', error: null },
		lastName: { name: 'Nachname', value: '', error: null },
		jobTitle: { name: 'Position', value: '' },
		tel: { name: 'Festnetz', value: '', error: null },
		areaCodeTel: { name: 'Festnetzvorwahl', value: 'DE' },
		mobil: { name: 'Handy', value: '', error: null },
		areaCodeMobil: { name: 'Handyvorwahl', value: 'DE' },
		interventionNumber: { name: 'Interventionsnummer', value: '', disabled: true, error: null },
		email: { name: 'Email', value: '', error: null },
		street: { name: 'Straße', value: '' },
		houseNumber: { name: 'Hnr', value: '' },
		zipCode: { name: 'Plz', value: '' },
		city: { name: 'Ort', value: '' },
		country: { name: 'Land', value: '' },
		rights: {
			projects: [],
			user: { read: false, write: false },
			invoice: { read: false, write: false },
			contracts: { read: false, write: false },
			company: { read: false, write: false },
			arming: { read: false, write: false }
		},
		groups: []
	}
	userStatus = _userStatus
	projects:any = {}
	users: any = []
	groups: any = {}
	activeStep = 1
	phoneCodes: any = {}
	steps: any = {
		1: { name: "Stammdaten", icon: "icon-user", done: false },
		2: { name: "Erweiterte Stammdaten", icon: "icon-user", done: false },
		3: { name: "Kontaktnummer", icon: "icon-user", done: false },
		4: { name: "Rechte", icon: "icon-user", done: false },
		5: { name: "Projektberechtigung", icon: "icon-user", done: false },
		// 6: { name: "Gruppen", icon: "icon-user", done: false }
	}
	
	validatePhoneNumber(field: string) {
		return new Promise(resolve => {
			const number: string = this.user[field].value
			const areaCode = field === "tel" ? this.user.areaCodeTel.value : this.user.areaCodeMobil.value
			
			if ( number === "" ) return true
			
			validatePhoneNumberRequest(number, areaCode).then((res: any) => {
				if ( res.status === "ERROR" ) {
					this.user[field].error = res.result
				} else {
					this.user[field].error = null
					const plainNumber = res.result.split(this.phoneCodes[areaCode])[1]
					this.user[field].value = plainNumber
				}
				
				resolve('resolved')
			})
		})
	}
	
	checkEmailExist() {
		return new Promise((resolve) => {
			checkEmailExistRequest(this.user.email.value).then((res: any) => {
				console.log('kekse und so ', res)
				if ( res.status == "OK" ) return resolve("")
				
				const fields = Object.keys(res.result.errorFields)
				fields.map((field: any) => {
					this.user[field].error = res.result.errorFields[field]
					console.log(field, res.result.errorFields[field])
				})
				return resolve("")
			})
		})
	}
	
	async next(step: number) {
		
		switch (step) {
			case 2:
				// proof from step 1
				this.user.email.error = null
				
				this.user.firstName.error = this.user.firstName.value === "" ? "Bitte geben Sie einen Namen an" : null
				this.user.lastName.error = this.user.lastName.value === "" ? "Bitte geben Sie einen Nachnamen an" : null
				this.user.email.error = this.user.email.value === "" ? "Bitte geben Sie eine Emailadresse an" : null
				if (this.user.email.value && this.user.email.value != "") {
					await this.checkEmailExist()
				}
				break
			case 3:
				// proof from step 2
				break
			case 4:
				// proof from step 3
				this.user.tel.error = null
				this.user.mobil.error = null
				this.user.interventionNumber.error = null
				if ( this.user.tel.value === "" && this.user.mobil.value === "" ) {
					this.user.tel.error = "Bitte geben Sie mindestens eine Nummer an."
					this.user.mobil.error = "Bitte geben Sie mindestens eine Nummer an."
				}
				
				if ( this.user.interventionNumber.value === "" ) {
					this.user.interventionNumber.error = "Bitte wählen Sie welche Nummer Sie als Interventionsnummer verwenden wollen"
				}
				
				if ( this.user.tel.value !== "" ) {
					await this.validatePhoneNumber('tel')
				}
				
				if ( this.user.mobil.value !== "" ) {
					await this.validatePhoneNumber('mobil')
				}
				
				
				break
			case 5:
				// proof from step 4
				break
			case 6:
				// proof from step 5
				break
		}
		
		// early return before next when errors
		const fields = Object.values(this.user)
		const isError = fields.filter((item: any) => item.error)
		
		console.log('is error: ', isError)
		
		if ( isError && isError.length > 0 ) return false
		
		console.log('komme ich hier her ??')
		
		this.activeStep = step
		this.steps[step-1].done = true
	}
	
	back(step: number) {
		this.activeStep = step
	}
	
	go(step: number) {
		if ( step !== 1 ) {
			if ( this.steps[(step-1)] && this.steps[(step-1)].done === false ) return false
		}
		
		this.activeStep = step
	}
	
	async onTypingNumber(type: string) {
		const lengthTel     = this.user.tel.value.length
		const lengthMobil   = this.user.mobil.value.length
		
		// const res = await this.validatePhoneNumber(type)
		
		this.user.interventionNumber.disabled = true
		if ( lengthTel > 6 && !this.user.tel.error ) this.user.interventionNumber.disabled = false
		if ( lengthMobil > 6 && !this.user.mobil.error ) this.user.interventionNumber.disabled = false
		
		if ( this.user.interventionNumber.disabled ) this.user.interventionNumber.value = ""
		
		if ( this.user[type].error && this.user.interventionNumber == type ) this.user.interventionNumber.value = ""
	}
	
	onProjectRightChange(evt: any, pid: string|number) {
		if ( evt.target.checked ) {
			this.user.rights.projects.push(pid)
		} else {
			const index = this.user.rights.projects.indexOf(pid)
			this.user.rights.projects.splice(index, 1)
		}
	}
	
	onGroupChange(evt: any, id: string|number) {
		if ( evt.target.checked ) {
			this.user.groups.push(id)
		} else {
			const index = this.user.groups.indexOf(id)
			this.user.groups.splice(index, 1)
		}
	}
	
	saveUser() {
		let user = {
			supervisor: this.user.supervisor.value,
			firstName: this.user.firstName.value,
			lastName: this.user.lastName.value,
			jobTitle: this.user.jobTitle.value,
			tel: this.user.tel.value,
			areaCodeTel: this.user.areaCodeTel.value,
			mobil: this.user.mobil.value,
			areaCodeMobil: this.user.areaCodeMobil.value,
			interventionNumber: this.user.interventionNumber.value,
			email: this.user.email.value,
			street: this.user.street.value,
			houseNumber: this.user.houseNumber.value,
			zipCode: this.user.zipCode.value,
			city: this.user.city.value,
			country: this.user.country.value,
			rights: this.user.rights,
			groups: this.user.groups
		}
		
		console.log('speichern von: ', user)
		
		
		// return false
		
		saveEmployeeRequest(user).then((res: any) => {
			if ( res.status == 'OK' ) {
				Swal.fire({
					icon: 'success',
					title: 'Benutzer erfolgreich angelegt',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					},
					timer: 2000
				}).then(() => {
					this.$router.push({ name: "EmployeeDetails", params: { id: res.result } })
				})
			} else {
				let errorMessages = ``;
				if (res?.result?.errorFields) {
					const errors = Object.values(res?.result?.errorFields);
					errorMessages = `Nachfolgend sind die Fehler aufgeführt:<br/>`;
					errors.forEach(error => {
						errorMessages += `${error}<br/>`;
					});
				}
				
				// error Meldung
				Swal.fire({
					icon: 'error',
					title: 'Fehler',
					html: errorMessages ? errorMessages : 'Es ist ein Fehler aufgetreten',
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-danger'
					}
				})
			}
		})
	}
	
	beforeMount() {
		// get all available phone codes
		getPhoneCodesRequest().then((res: any) => {
			this.phoneCodes = res.result
		})
		
		// get all available projects (only if i got rights for it)
		getProjectsOverviewRequest().then((res: any) => {
			this.projects = {}
			if ( !res.result ) return false
			
			res.result.projekte.map((project: any) => {
				this.projects[project.pid] = project
			})
		})
		
		// get all groups if i got into it
		getEmployeeGroupsByEmployeeRequest().then((res: any) => {
			if ( res.status === "OK" && res.result.length > 0 ) {
				res.result.map((item: any) => {
					this.groups[item.id] = item
				})
			}
		})
		
		// get all employees
		getEmployees().then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					this.users.push(emp)
				})
			}
		})
	}
}
