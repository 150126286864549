
import {Options, Vue} from "vue-class-component";
import StandardRequest from "@/views/Ticket/TicketForms/StandardRequest.vue";
import {sendTicketRequest} from "@/services/TicketService";

@Options({
	components: { StandardRequest  }
})
export default class TicketNew extends Vue {
	ticket: any = {
		message: "",
		category: 0
	}
	categories: any = ["Bitte wählen", "Vertrag", "Anlage", "Schaltzeiten", "Rechnung", "Intervetnionen", "Kontakte", "Alarm"]
	
	sendRequest() {
		const data = {
			message: this.ticket.message,
			category: this.ticket.category,
			type: "standard"
		}
		sendTicketRequest(data).then((res: any) => {
			console.log(res)
		})
	}
}
