
import {Options, Vue} from 'vue-class-component'
import {getContactsOverviewRequest} from "@/services/ObjectService"
import {getInterventionTypesRequest} from "@/services/InterventionService"
import { getEmployees } from '@/services/EmplyeeService'
import {hideLoadingLayer, stringToDate, _userStatus, showLoadingLayer} from "@/services/Globals"
import LightDonutChart from "@/components/Object/LightDonutChart.vue"

@Options({
	components: {LightDonutChart}
})
export default class ContactsOverview extends Vue {
	headline = 'Übersicht Ihrer zugewiesenen Kontakte'
	projects: any = {}
	interventionTypes: any
	employees: any[] = []
	userStatus = _userStatus
	
	get filteredEmployees(): any {
		let emps: any = {}
		
		this.employees.map((item: any) => {
			item.status = parseInt(item.status)
			emps[item.id] = item
		})
		
		return emps
	}
	
	configContacts() {
		this.$router.push({ name: 'ContactConfigProjects' })
	}
	
	stringToDate(date: string, lang: string): any {
		return stringToDate(date, lang)
	}
	
	showContacts(objectId: any, systemId: any, type: any) {
		// this.$router.push({ name: 'SystemInterventionTypeContacts', params: { id: objectId, system: systemId, type: type } })
		this.$router.push({ name: 'ObjectSystemContactInterventionTypes', params: { id: objectId, system: systemId, type: type } })
	}
	
	showEmployee(contactId: any) {
		this.$router.push({ name: 'EmployeeDetails', params: { id: contactId } })
	}
	
	goToSystemSwitchingTimes(objectId: any, systemId: any) {
		this.$router.push({ name: 'ObjectSystemContactInterventionTypes', params: { id: objectId, system: systemId } })
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
		await getEmployees().then((res: any) => {
			if ( res.result && res.result.length > 0 ) {
				console.log('ausgabe', res.result)
				this.employees = res.result
			}
		})
		
		await getContactsOverviewRequest().then((res: any) => {
			console.log(res)
			if ( res.result ) {
				
				this.projects = res.result
			}
		})
		
		hideLoadingLayer()
	}
}
