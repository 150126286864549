
import { resetPasswordRequest } from "@/services/AuthService"
import { Vue } from "vue-class-component"

export default class ForgotPassword extends Vue {
	frm: any = {
		email: { value: '' }
	}
	resetSuccess = false
	
	backToLogin() {
		this.$router.push({name: 'SignIn'})
	}
	
	resetPassword() {
		resetPasswordRequest(this.frm.email.value).then((res: any) => {
			if ( res.status == 'OK' ) {
				this.resetSuccess = true
			}
		})
	}
}
