
import { linkAccountRequest } from "@/services/AuthService"
import { Vue } from "vue-class-component"
import Swal from "sweetalert2";

export default class LinkAccount extends Vue {
	headline = 'Neuen Account verknüpfen'
	username = {
		value: ""
	}
	password = {
		value: "",
		protected: true
	}
	
	linkAccount() {
		linkAccountRequest(this.username.value, this.password.value).then((res: any) => {
			if ( res.status === "OK" ) {
				console.log('kese')
				Swal.fire({
					title: "Erfolgreich",
					text: "Ihre Accounts wurden verknüpft",
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn btn-primary"
					},
					confirmButtonText: "Weiter"
					// timer: 2000
				}).then((res: any) => {
					console.log(res)
					if ( res.isConfirmed || ( res.dismiss === 'timer' && res.isDismissed )) {
						// this.$router.push({ name: 'LinkedAccounts' })
						// wee need to reload page
						window.location.href = "/linked-accounts"
					}
				})
				
			}  else {
				Swal.fire({
					title: "Fehler!",
					html: "Der Account konnte nicht verknüpft werden.",
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn bg-danger"
					},
					confirmButtonText: "Schließen"
				})
			}
		})
	}
	
	backToAccounts() {
		this.$router.push({ name: 'LinkedAccounts' })
	}
}
