
import { getProjectsOverviewRequest } from "@/services/ObjectService"
import { Vue } from "vue-class-component"

export default class SwitchingTimesProjects extends Vue {
	headline = "Schaltzeiten konfigurieren"
	projects: any = []
	
	nextStep(projectId: any) {
		this.$router.push({ name: 'SwitchingTimesConfigSystems', params: { id: projectId } })
	}
	
	beforeMount() {
		getProjectsOverviewRequest().then((res: any) => {
			if ( res.status === "OK" ) {
				this.projects = res.result.projekte
			}
		})
	}
}
