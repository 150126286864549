
import { Vue, Options } from "vue-class-component"
import { _countries } from '@/config/conf'
import { getCompanyDataRequest } from "@/services/CostumerService"
import { saveCompanyRequest } from "@/services/Company"
import Swal from "sweetalert2";
import {getEmployees} from "@/services/EmplyeeService";

@Options({})
export default class Company extends Vue {
	showDropdown = false
	search = ""
	users: any[] = []
	countries = _countries
	frmCompany: any = {
		kid: { name: 'Kundennummer', value: '', error: null },
		accountType: { name: 'Account Typ', value: '', error: null },
		salutation: { name: 'Anrede', value: '', error: null },
		title: { name: 'Titel', value: '', error: null },
		company: { name: 'Firma', value: '', error: null },
		firstName: { name: 'Vorname', value: '', error: null },
		lastName: { name: 'Name', value: '', error: null },
		street: { name: 'Straße', value: '', error: null },
		houseNumber: { name: 'Hnr', value: '', error: null },
		zipCode: { name: 'Plz', value: '', error: null },
		city: { name: 'Ort', value: '', error: null },
		additionalAddress: { name: 'Adresszusatz', value: '', error: null },
		country: { name: 'Land', value: '', error: null },
		ansprechpartner: { name: 'Name', value: '', error: null },
		// phone: { name: 'Hauptrufnummer', value: '', error: null },
		phone: { name: 'Festnetznummer', value: '', error: null },
		mobil: { name: 'Mobilnummer', value: '', error: null },
		email: { name: 'Email', value: '', error: null },
	}
	
	get userRights() {
		return this.$store.getters.rights
	}
	
	searchEmployees() {
		const data = {
			search: this.search,
			filter: {
				status: [2]
			}
		}
		getEmployees(data).then((res: any) => {
			this.users = []
			if ( res.result ) {
				res.result.map((emp: any) => {
					emp.status = parseInt(emp.status)
					this.users.push(emp)
				})
			}
		})
	}
	
	chooseEmployee(user: any) {
		// console.log(user)
		this.frmCompany.ansprechpartner.value = user.firstName + ' ' + user.lastName
		this.frmCompany.mobil.value = user.mobil
		this.frmCompany.phone.value = user.tel
		this.frmCompany.email.value = user.email
		
		this.search = ""
		this.showDropdown = false
	}
	
	toggleDropdown(evt: any) {
		evt.stopPropagation()
		evt.preventDefault()
		this.showDropdown = !this.showDropdown
	}
	
	saveCompany() {
		let data: any = {}
		const frmArr = Object.keys(this.frmCompany)
		
		frmArr.map((key: any) => {
			data[key] = this.frmCompany[key].value
		})
		
		saveCompanyRequest(data).then((res: any) => {
			if ( res.status === "ERROR" ) {
				const errors = res.result.errorFields
				const errorKeys = Object.keys(errors)
				
				Swal.fire({
					icon: "error",
					title: "Fehler",
					text: res.result.errorMsg,
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-danger'
					}
				})
				
				errorKeys.map((item: any) => {
					this.frmCompany[item].error = errors[item]
				})
			} else {
				Swal.fire({
					icon: "success",
					title: "Erfolgreich",
					text: "Daten wurden erfolgreich übernommen",
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary'
					}
				})
			}
		})
	}
	
	getCompanyData() {
		getCompanyDataRequest().then((res: any) => {
			if ( !res.result ) return false
			const userData = res.result
			
			switch (userData.accountType) {
				case "1":
					userData.accountType = 'Privat'
					break
				case "2":
					userData.accountType = 'Geschäftskunde'
					break
				default:
					userData.accountType = 'Privat'
			}
			
			this.frmCompany.kid.value = userData.kid
			this.frmCompany.accountType.value = userData.accountType
			this.frmCompany.salutation.value = userData.anrede
			this.frmCompany.title.value = userData.titel
			this.frmCompany.company.value = userData.firma
			this.frmCompany.firstName.value = userData.vorname
			this.frmCompany.lastName.value = userData.name
			this.frmCompany.street.value = userData.strasse
			this.frmCompany.houseNumber.value = userData.hnr
			this.frmCompany.zipCode.value = userData.plz
			this.frmCompany.city.value = userData.ort
			this.frmCompany.country.value = userData.land
			this.frmCompany.additionalAddress.value = userData.adresszusatz
			this.frmCompany.ansprechpartner.value = userData.ansprechpartner
			this.frmCompany.phone.value = userData.tel
			this.frmCompany.mobil.value = userData.funk
			this.frmCompany.email.value = userData.email
		})
	}
	
	beforeMount() {
		this.getCompanyData()
		
		this.searchEmployees()
	}
	
}
