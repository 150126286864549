
import { Vue } from "vue-class-component"
import {confirmPhoneValidationRequest} from "@/services/AuthService";
import Swal from "sweetalert2";
import {changeInterventionNumberByHashRequest, changeInterventionNumberRequest} from "@/services/UserService";
import {checkForNotSynced} from "@/services/BackgroundTasks";

export default class ConfirmPhoneValidation extends Vue {
	frm: any = {
		pin: { name: 'PIN', value: '', error: null }
	}
	
	get hash(): any {
		return this.$route.params.hash
	}
	
	get user() {
		return this.$store.getters.user
	}
	
	confirmPhoneValidation() {
		confirmPhoneValidationRequest(this.frm.pin.value, this.hash).then((res: any) => {
			if (res.status === "ERROR") {
				const errors = res.result.errorFields
				const errorKeys = Object.keys(errors)
				
				errorKeys.map((item: any) => {
					this.frm[item].error = errors[item]
				})
				
				Swal.fire({
					title: "Fehler",
					text: "Bitte bearbeiten Sie alle rot markierten Felder",
					icon: "error",
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn bg-danger'
					}
				})
			} else {
				Swal.fire({
					icon: "success",
					title: "Erfolgreich",
					html: `
						<h5>Ihre Telefonnummer wurde geändert.</h5>
						<span>Möchten Sie diese Nummer als Interventionskontakt verwenden ?</span>
						<span>Bitte ggfs. danach einloggen um die Änderung an die Leitstelle zu übertragen.</span>
					`,
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn bg-light'
					},
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: "<i class='icon-check2'></i> Ja, diese Nummer verwenden",
					cancelButtonText: "<i class='icon-cross'></i> Nein, nicht ändern"
					// timer: 2000
				}).then((result: any) => {
					if ( result.isConfirmed ) {
						// call to api
						if ( this.user.accessToken ) { // if logged in
							changeInterventionNumberRequest('mobil').then(() => {
								this.$router.push({ name: 'ChangePhoneNumber' })
							})
							checkForNotSynced()
						} else { // if not logged in
							changeInterventionNumberByHashRequest('mobil', this.hash).then(() => {
								this.$router.push({ name: 'ChangePhoneNumber' })
							})
						}
						
						
					} else if ( result.isDismissed || result.isDenied ) {
						this.$router.push({ name: 'ChangePhoneNumber' })
					}
					
				})
			}
		})
	}
}
