
import {Options, Vue} from "vue-class-component";
import {responseToTicketRequest} from "@/services/TicketService";
import Swal from "sweetalert2";

@Options({
	props: {
		ticket: {
			default: {}
		}
	}
})
export default class Standard extends Vue {
	ticket: any
	frm: any = {
		message: { name: "Nachricht an Support", value: "" },
		files: { name: "Dateianhänge", value: [] },
	}
	showAttachments = false
	
	removeFile(fileIndex: any) {
		this.frm.files.value.splice(fileIndex, 1)
	}
	
	onFilesChange(evt: any) {
		// this.frm.files.value = []
		const filesObject = evt.target.files
		
		const files = Object.values(filesObject)
		
		files.map((file: any) => {
			this.frm.files.value.push(file)
		})
	}
	
	responseTicket() {
		const data: any = {
			id: this.ticket.id,
			text: this.frm.message.value
		}
		
		if ( this.frm.files.length > 0 ) {
			data.files = this.frm.files.value
		}
		
		responseToTicketRequest(data).then((res: any) => {
			if ( res.result ) {
				Swal.fire({
					title: "Erfolgreich",
					text: "Sie haben auf das Ticket geantwortet",
					buttonsStyling: false,
					customClass: {
						confirmButton: "btn btn-primary"
					}
				}).then(() => {
					// whatever
				})
				
				this.frm.message.value = ""
				this.frm.files.value = []
				this.$emit('reload')
			}
		})
	}
}
