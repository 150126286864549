
import {Options, Vue} from "vue-class-component";
import FilterBar from "@/components/FilterBar.vue";
import {getFilterRequest, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import moment from "moment";
import {getSystemLogsRequest} from "@/services/ObjectService";

@Options({
	components: { FilterBar }
})
export default class SystemLogs extends Vue {
	headline = 'Anlagen - Logs'
	filter: any = {}
	filterArr: any = {}
	logs: any = []
	search = ''
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		this.getLogs()
	}
	
	formatDateTime (datestring: string) {
		return moment(datestring).format('DD.MM.YYYY HH:mm')
	}
	
	getLogs() {
		const data: any = {
			search: this.search,
			filter: this.filterArr,
			limit: 2500
		}
		
		getSystemLogsRequest(data).then((res: any) => {
			this.logs = []
			if ( res.result ) {
				res.result.map((log: any) => {
					
					this.logs.push(log)
				})
			}
			
			hideLoadingLayer()
		})
	}
	
	beforeMount() {
		showLoadingLayer()
		
		getFilterRequest('systemLogs').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			
			this.filter = res.result.filter
		})
		
		this.getLogs()
	}
}
