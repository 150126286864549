
import { Vue } from "vue-class-component"
import {getInterventionsOverviewRequest, getInterventionTypesRequest} from "@/services/InterventionService";
import {_timeRanges, hideLoadingLayer, showLoadingLayer, stringToDate, validateWeekday} from "@/services/Globals";

export default class InterventionsOverview extends Vue {
	headline = 'Übersicht aller Interventionen'
	projects: any = {}
	interventionTypes: any = {}
	timeRanges = _timeRanges
	
	editIntervention(id: any, systemId: any, type: any, interventionId: any) {
		// kuchen
		this.$router.push({ name: 'ObjectSystemInterventionDetails', params: { id: id, system: systemId, type: type, intervention: interventionId } })
	}
	
	showInterventions(objectId: any, systemId: any, type: any) {
		// this.$router.push({ name: 'SystemInterventionTypeInterventions', params: { id: objectId, system: systemId, type: type } })
		this.$router.push({ name: 'ObjectSystemInterventionInterventionTypes', params: { id: objectId, system: systemId, type: type } })
	}
	
	showSystemOverview(objectId: any, systemId: any) {
		// this.$router.push({ name: 'ObjectSystemOverview', params: { id: objectId, system: systemId } })
		this.$router.push({ name: 'ObjectSystemInterventionInterventionTypes', params: { id: objectId, system: systemId } })
	}
	
	stringToDate(date: string, lang: string): any {
		return stringToDate(date, lang)
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
		await getInterventionsOverviewRequest().then((res: any) => {
			console.log(res)
			if ( res.result ) {
				const projectKeys = Object.keys(res.result)
				
				projectKeys.map((projectId: any) => {
					if ( res.result[projectId].systems ) {
						const systemKeys = Object.keys(res.result[projectId].systems)
						
						systemKeys.map((systemId: any) => {
							if ( res.result[projectId].systems[systemId].interventionTypes ) {
								const interventionTypeKeys = Object.keys(res.result[projectId].systems[systemId].interventionTypes)
								
								interventionTypeKeys.map((typeId: any) => {
									if ( res.result[projectId].systems[systemId].interventionTypes[typeId].interventions ) {
										const interventions = res.result[projectId].systems[systemId].interventionTypes[typeId].interventions
										let filteredInterventions: any = []
										
										interventions.map((intervention: any) => {
											
											filteredInterventions.push({
												id: intervention.id,
												systemId: intervention.systemId,
												contactOrder: intervention.contactOrder,
												interventionType: intervention.interventionType,
												timeRange: intervention.timeRange
											})
										})
										
										res.result[projectId].systems[systemId].interventionTypes[typeId].interventions = filteredInterventions
									}
								})
							}
						})
					}
				})
				
				this.projects = res.result
				
				hideLoadingLayer()
			}
		})
	}
}
