
import { Vue, Options } from "vue-class-component"
import {getAlertsRequest} from "@/services/AlertService";
import {getFilterRequest, hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import FilterBar from "@/components/FilterBar.vue";

@Options({
	components: {FilterBar},
	watch: {
		objectId() {
			this.getAlerts()
		}
	}
})
export default class Alerts extends Vue {
	headline = 'Alarme'
	alerts: any[] = []
	filter: any = {}
	filterArr: any = []
	search = ""
	
	get filteredAlerts() {
		return this.filterAlerts(this.alerts)
	}
	
	get objectId():any {
		return this.$route.params.id ? this.$route.params.id : null
	}
	
	filterAlerts(alerts: any) {
		let ret: any[] = []
		if ( alerts ) {
			alerts.map((item: any) => {
				ret.push(item)
			})
		}
		return ret
	}
	
	onFilter(args: any) {
		this.filter     = args.filter
		this.search     = args.search
		this.filterArr  = args.filterArray
		
		this.getAlerts({filter: this.filterArr, search: this.search})
	}
	
	showTicket(ticketId: number) {
		this.$router.push({ name: "TicketDetail", params: { id: ticketId } })
	}
	
	showDetail(alert: any) {
		this.$router.push({ name: 'AlertPreview', params: { id: alert.alarm_id } })
	}
	
	getAlerts(args = {}) {
		const data: any = args
		if ( this.objectId ) data.objectId = this.objectId
		
		getAlertsRequest(data).then((res: any) => {
			if ( res.result ) this.alerts = res.result
			
			hideLoadingLayer()
		})
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		getFilterRequest('alerts').then((res: any) => {
			if ( !res.result || !res.result.filter ) return false
			
			this.filter = res.result.filter
		})
		
		this.getAlerts()
	}
}
